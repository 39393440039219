
/* Secure Patient Communication styles Start */ 
$spc-icons-clr: #858585;
$spc-borders-clr: #D9DADE;
$spc-back-clr: #EEEEF0;
$spc-chat-hist-clr: #9094A0;
$spacer-16: 16px;

.spc-container { 
    display: grid; 
    grid-template-columns: map-get($grid-col-Bpts, '8-12');
    grid-template-areas:
        "spc-search" 
        "spc-input";
    column-gap: $spacer-16; 
    margin: 0 auto;
    width: map-get($dash-body-sec, 'grid-width'); 
    .noInterNet{
        color: #f6251a;  
    }
    .spc-main {
        display: grid;
        grid-column: 5 / 11;        
        box-shadow: 0px 2px $spacer rgba(46, 47, 53, 0.10);
        border-radius: $spacer; 
        border: 1px $spc-borders-clr solid;
        margin-top: -56px;
        background-color: $body-color;
        height: 83vh;
        grid-template-rows: auto 1fr auto;

        .spc-inputTxt {
            flex: 1;
            border: none;
            outline: none;
            background: none;
            font-size: 13px;
            color: $spc-icons-clr;
            padding: 5px 10px;
        
            &::placeholder {
                color: #aaa;
            }
        }
        .spc-inputTxt::placeholder {
            color: gray;
        }
        .spc-inputTxt.error-placeholder::placeholder {
            color: #f6251a; 
        }
        //header section 
        .spc-search {
                display: grid;
                grid-row: 1;
                grid-template-columns: auto 9%;
                grid-template-rows: 56px;
                border-bottom-color: $spc-borders-clr;
                border-bottom-style: solid;
                border-bottom-width: thin;
            .search-bar {
                    display: flex;
                    border-radius: 100px;
                    background-color: $spc-back-clr; 
                    margin: $spacer + 4 0 $spacer + 4 41px;
                    padding: $spacer - 2 0px $spacer - 2 $spacer + $spacer;
                    align-items: center;
                    span {
                        color: $spc-icons-clr;
                        font-size: 20px;
                    }
                
                .search-icon {
                    margin-right: 10px;
                    font-size: 13px;
                    color: $spc-icons-clr;
                }
            }
            .spc-filter {
                display: flex;
                align-items: center;

                span {
                    color:$spc-chat-hist-clr;
                    margin:0 $spacer * 3 0px 9px;
                    cursor: pointer;
                }
            }
        } 
        //bottom section 
        .spc-input {
            display: grid;
            grid-row: 3;
            grid-template-columns: auto 10.5%;
            grid-template-rows: 40px;
            padding: $spacer * 2 $spacer * 3 $spacer * 4 $spacer * 3;

            .spc-enter {
                display: flex;
                align-items: center;
                background-color: $spc-back-clr;
                padding: 0; 
                background: $white; 
                border-radius: 100px; 
                border: 1px #DFE4E9 solid;

                span {
                    color: $spc-icons-clr;
                    padding-left: $spacer;
                    cursor: pointer;
                }
                span.camera {
                    padding: $spacer * 2 $spacer * 2;
                }
            }
            .send-button{
                background-image: url('/assets/images/send.svg');
                background-size: 20px 20px; 
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background: #7A27C9; 
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                width: 40px;  
                height: 40px; 
                cursor: pointer;
                margin-left: $spacer * 2;
                padding: $spacer;
                svg {
                    fill: $white;
                }
            }
            .send-icon {
                width: 24px;
                height: 24px;
            }
        }
        .spc-history {
            font-family: $inter-font-family;
            .spc-from-pharmacist {
                    .spc-your-pharmacist, .spc-recieved-date-time {
                        margin: 4px 0 4px 0;
                        font-size: 10px;
                    }
                    margin:0 $spacer * 3 + 2; 
                span {
                    font-size: 13px;
                    width: auto; 
                    height: auto; 
                    padding-left: $spacer + 4; 
                    padding-right: $spacer + 4; 
                    padding-top: $spacer; 
                    padding-bottom: $spacer; 
                    background: $spc-back-clr; 
                    border-top-left-radius: $spacer-16; 
                    border-top-right-radius: $spacer-16; 
                    border-bottom-right-radius: $spacer-16; 
                    border-bottom-left-radius: $spacer - 4;
                    flex-direction: column; 
                    justify-content: center; 
                    align-items: flex-end; 
                    display: inline-flex;
                    line-height: 1.49;
                    word-break: break-all;
                }
                .spc-pharmacist-msg {
                    display: grid;
                    justify-content: start;
                    max-width: 80%;
                }
            }
            .spc-frm-you {
                margin:0 $spacer * 3 + 2;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .spc-frm-patient, .spc-sent-date-time {
                    margin: 4px 0 4px 0;
                    font-size: 10px;
                }
                .spc-frm-msg {
                    span{
                        font-size: 13px;
                        width: auto;
                        height: auto; 
                        padding-left: $spacer + 4; 
                        padding-right: $spacer + 4; 
                        padding-top: $spacer; 
                        padding-bottom: $spacer; 
                        right: 0px;
                        background: #E8D6FE; 
                        border-top-left-radius: $spacer-16;
                        border-top-right-radius: $spacer-16; 
                        border-bottom-right-radius: $spacer - 4; 
                        border-bottom-left-radius: $spacer-16;
                        flex-direction: column; 
                        justify-content: flex-end; 
                        align-items: flex-end; 
                        display: inline-flex;
                        line-height: 1.49;
                        word-break: break-all;
                    }
                }
                .spc-sent-date-time {
                    display: flex;
                    align-items: center;
                    img{
                        font-size: $spacer-16 + 2;
                        margin-left: 5px;
                    }
                }
                .spc-frm-msg {
                    display: grid;
                    justify-content: end;
                    max-width: 80%;
                }
            }
            .chat-history{
                .date-header {
                    display: flex;
                    text-align: center;
                    overflow: hidden;
                    white-space: nowrap;
                    justify-content: center;
                }
               
                .date-header > span {
                    display: inline-flex;
                    align-items: center;
                    background-color: #e4e4e9;
                    padding: 2px 6px;
                    border-radius: 8px;
                    border: #ccc solid 1px;
                    position: relative;
                    display: inline-block;
                    font-size: 12px;
                }
               
                .date-header > span:before,
                .date-header > span:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    width: 200px;
                    height: 1px;
                    background: #e4e4e9;
                }
               
                .date-header > span:before {
                    right: 100%;
                    margin-right: 15px;
                }
               
                .date-header > span:after {
                    left: 100%;
                    margin-left: 15px;
                }
                .no-matches {
                    text-align: center;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #858585;
                    margin-top: 50%;
                }
            }
        }
        .spc-history {
            transition: height 0.3s ease;
            overflow-y: auto;
            grid-row: 2; 
        }
        .spc-history::-webkit-scrollbar { 
            width: 5px;
            padding-right: 5px;
        }
        .spc-history::-webkit-scrollbar-track {
            background-color: #EEEEF0;
            border: 1px solid #EEEEF0;
        }
        .spc-history::-webkit-scrollbar-thumb { 
            background-color: #B7B9C2;
        } 
        .message-container{
            padding-right: 5px;

        }
    }
}   

/* Tablet view */
@media screen and (max-width: 1023px) {
    .spc-container {
        grid-template-columns: map-get($grid-col-Bpts, '8-8');
        column-gap: $spacer * 2;
        grid-column: 2 / span 8;

        .spc-main {
            grid-column: 2 / span 8;
        }
    }
}
/* Mobile view */
@media screen and (max-width: 720px) {
    html,body {
        overflow: hidden;
    }
    .spc-container {
        grid-template-columns: map-get($grid-col-Bpts, '0-4');
        column-gap: $spacer * 2;
        
        .spc-main {
            grid-column: 2 / span 4;
            height: 100vh;
        }
        .spc-main {
            grid-column: 2 / span 4;
            box-shadow:none;
            border:none;
            border-radius:0px;
            .spc-search {
                border-bottom-color:$white;
                border-bottom-style:none;
                border-bottom-width: 0;
                margin-top: 70px;
                .search-bar { 
                    margin: 12px 0 12px 0;
                    padding: 0px 0 0px 16px; 
                }
            }
            .spc-history { 
                margin-bottom: 85px;
                .spc-from-pharmacist{
                    margin: 0 0;
                    .spc-pharmacist-msg { 
                        max-width: 90%;
                    }
                }
                .spc-frm-you{
                    margin: 0 0;
                    .spc-frm-msg { 
                        max-width: 90%;
                    }
                }
            }
            .chat-history{
                .date-header > span:before,
                .date-header > span:after {
                    width: 100px;
                }
                .no-matches {
                    margin-top: 100%;
                }
            }
            .spc-input {
                padding:0px;
                padding: 8px 0px 16px;
                background:linear-gradient(to top, #f9f9f9, transparent);
                position: sticky;
                grid-template-columns: auto 16%;
                bottom: 0;
                .spc-enter {
                    width: 100%;
                }
                .spc-send {
                    margin-left: 8px;
                }
                .send-button {
                    margin-left: 8px;
                }
            }
        }
        
    }
}

/* Secure Patient Communication styles End */ 