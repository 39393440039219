/* You can add global styles to this file, and also import other style files */
@import '@fortawesome/fontawesome-pro/css/all.css';

@import "assets/scss/fontface";
@import "assets/scss/input";

/* myPrimeRx */ 

:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem; 
  --white-color: #FFFFF; 
}

$spacer : 8px; 
$body-color:#F9F9F9;
$sora-font-family: "Sora", sans-serif;
$inter-font-family: "Inter", sans-serif;
$google-fonts:'Material Symbols Outlined';

html,
body {
  background: $body-color;
  font-family: $sora-font-family;
  margin: 0;
  padding: 0;
}

input, button, select, textarea, optgroup, option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
}

*, *:before, *:after {
  box-sizing: border-box;
}

.masked-image img {
  filter: blur(5px);
  -webkit-filter: filter(5px);
  -moz-filter: filter(5px);
  -o-filter: filter(5px);
  -ms-filter: filter(5px);
}

/* Header colors */
$danger-clr:red;
$hb-shdw:#DFE4E9;
$white: #FFFFFF;
$purple: #7A27C9;
$darkPink: #FE0181;
$green: #039D00; 

/* Dashbaord colors */

$box1: #4285F4;
$box2: #10B981;
$box3: #8B5CF6;
$box4: #AA5A13;
$box4-color: #AA5A13;
$box5: #06B6D4;
$text-color: #2E2F35;
$phone-bg-clr: #8F38E5;
$right-bg-clr:#EFF6FF;
$right2-bg-clr: #ECFDF7;
$right3-bg-clr: #F7F3FF;
$right4-bg-clr: #FEF7E8;
$right5-bg-clr: #ECFCFF;
$right6-bg-clr: #DBF8FD;
$rightclr: #006C90;
$text-light-clr: #5B5C64;

/* RxHistory colors */

$pending-pay-clr: #4285F4;
$myMeds-clr: #10B981;
$ready-clr: #8B5CF6;
$new-Pre-clr: #06B6D4  ;
$refillDue-clr: #CA8A04;

/* Pending Payment btn color */
$btn-pending: #62A5F8;
$pending-time: #FFF5D0;
$amount-clr: #2763E9;

$dash-body: (
  'width': 100%,
  'height':100%,
  'grid-width':1440px,
);

/* Footer colors */
$ftr-clr:#EBEBEB;

$dash-body-sec: ( 
  'width': 100%,
  'height':100%,
  'grid-width':1440px
);

$dash-header-sec: (
  'h-height': 56px
);

$grid-col-Bpts: (
  'one-ten': 1 / span 10, 
  'three-ten': 3 / span 10,
  'two-eight': 2 / span 8,
  'two-four': 2 / span 4,
  '8-12': 24px repeat(12,1fr) 24px,
  '8-8': 8px repeat(8, 1fr) 8px,
  '8-6': 8px repeat(6, 1fr) 8px,
  '24-8': 24px repeat(8, 1fr) 24px,
  '12-4': 12px repeat(4, 1fr) 12px,
  '0-4': 0px repeat(4, 1fr) 0px,
  '16-4': 16px repeat(4, 1fr) 16px,
  );

$font-sizes: (
  'fs-12': 12px,
  'fs-14': 14px,
  'fs-16': 16px,
  'fs-18': 18px,
  'fs-19': 19px,
  'fs-20': 20px,
  'fs-22': 22px,
  'fs-24': 24px,
);

$font-weights: (
  'fw-3': 300,
  'fw-4': 400,
  'fw-5': 500,
  'fw-6': 600,
  'fw-7': 700
);

@import "assets/scss/spc";

body, html{
  font-family: $sora-font-family !important;
  background-color: $body-color !important;
  background-image: url('/assets/images/bgpattern.png') !important;
  background-repeat: no-repeat !important;
  background-position: right bottom !important;
  height: 100%;
}
.wrapper { 
  display: grid; 
  grid-template-rows:auto auto auto 1fr auto;
  grid-template-columns: map-get($grid-col-Bpts, '8-12');
  grid-template-areas: "heading-text" "app-banner" "heading-strip" "main" "footer";
  column-gap: $spacer * 2;
  width: 100%;
  margin: 0 auto;
  width: map-get($dash-body-sec, 'grid-width'); 
}
.header {
  display: grid;
  width: 100%;
  grid-template-rows: 64px;
  box-shadow: 0px 2px 8px #EAEAEB;
  border-bottom: 1px $hb-shdw solid;
  background: $white;
  position: relative;
}
.header::after {
  content: 'Offline';
  position: absolute;
  text-align: center;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  bottom: 0;
  left: 0;
  height: 0;  
  width: 100%;
  background-color: #FF9494;
  transition: height 0.5s ease;
  overflow: hidden;
  color: $white;
  z-index: 10;
}
.header.no-internet::after {
  height: 10px;  
}
.head-nav {
  column-gap: $spacer * 2;
  display: grid;
  grid-template-columns: map-get($grid-col-Bpts, '8-12'); 
  min-width: map-get($dash-body-sec, 'grid-width');
  margin: 0 auto;
}
footer {
  display: none; //felx
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  background-color: $ftr-clr;
  height: 40px;
  align-items: center;
  font-size: 11px;
}
.nodataTxt {
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-top: 10%;
}
.hd{
    &--left{ 
      display: flex;
      align-items: center;
      grid-column: 3 / 8; 
    }
    &--right{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      grid-column: 8 / 13; 
    }
}
.text-danger{
  color: $danger-clr;
}
/* subscription start*/
.icon-default #icon-path {
  fill: #FF3333;
}

.icon-active #icon-path {
  fill: #00C8B7; 
}
.sub-alert{
    display: grid;
    grid-template-columns: 24px repeat(12, 1fr) 24px;
    grid-template-areas: "sub-user";
    column-gap: 16px;
    height: 100%;
    align-items: center;
    width: 1440px;
    margin: 0 auto;
    
    .sub-user{
        grid-area: sub-user;
        grid-column: 6 / 10;
        display: grid;
        grid-template-columns: 1fr; 
        justify-self: center;
        align-self: center;
        .sub-pharma {
            display: flex;
            flex-direction: column;
            margin: 74px;
            margin: 0 auto 74px auto;
            span {
                font-weight: 500;
                font-size: 22px;
                line-height: 1.1;
            }
            span:nth-child(1) {
                color: #7a27c9;
            }
            span:nth-child(2) {
                color: #fe0181;
            }
        } 
        .sub-access{
            border-radius: $spacer * 2;
            background-color: #FFF;
            box-shadow: 0px 2px 8px rgba(46, 47, 53, 0.10);
            height: 284px;
            width: 550px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; 
            padding: 51px 55px;
            .sub-img {
                margin-bottom: 23px;
            }
            .sub-title {
                margin-bottom: $spacer;
                font-family: $sora-font-family;
                font-size: $spacer * 2; 
                font-weight: 500;
                color: #7A27C9;
            }
            .sub-msg {
                font-family: $inter-font-family;
                font-size: 14px;
                text-align: center;
                a.err{
                  color: #FE0181;
                  text-decoration: none;
                  cursor: pointer;
                }
            }
        }
        .sub_singup_button{
          padding: 5px 20px;
          cursor: pointer;
        }
        .already_have_acct{
          padding-top: 15px;
          font-size: 15px;
        }
        .sub_login_button{
          background-color: transparent;
          border: none;
          color: #8f38e5;
          cursor: pointer;
          font-weight: 500;
        }
    }
}
/* subscription end*/

/* Auth0 styles start */
.auth0-login-grid {
      display: grid;
      grid-template-columns: 24px repeat(12, 1fr) 24px;
      grid-template-areas: "login-user";
      column-gap: 16px;
      height: 100%;
      align-items: center;
      width: 1440px;
      margin: 0 auto; 
          .login-user {
              grid-area: login-user;
              grid-column: 4 / 12;
              height: 434px;
              display: grid;
              grid-template-columns: 1fr 1fr;
              place-items: center;
              background-color: #fff;
              box-shadow: 0 2px 8px #2e2f351a;
              border-radius: 16px;
                .login-pharma {
                    .login-pharma-img {
                        span{
                            font-weight: 500;
                            font-size: 22px;
                            line-height: 1.1;
                        }
                        span:nth-child(1) {
                            color:$purple;
                        }
                        span:nth-child(2) {
                            color: $darkPink;
                        }
                        span:nth-child(3) {
                            font-size: $spacer + 4;
                            font-family: $inter-font-family;
                            margin-top: $spacer;
                        }
                        display: flex;
                        flex-direction: column;
                        > img {
                          width: 274px;
                        }
                    }
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    width: 100%;
                    justify-content: space-between;
                    padding: $spacer * 5;
                }
              .login-access {
                  .login-form {
                    input[type="email"],
                    input[type="password"]{
                        height: 40px;
                        margin-bottom: 8px;
                        width: 100%;
                        height: 40px;
                        border-radius: 8px;
                        border: 1px solid #B7B9C2;
                        background: #fff; 
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal; 
                        padding-left: 8px;
                        color: #2E2F35;
                    }
                    input[type="password"]{
                      margin-bottom: 0px;
                    }
                    input[type="email"]:focus,
                    input[type="password"]:focus {
                        box-shadow: none;
                        outline: none;
                        border: 1px solid #7A27C9;
                        background: transparent;
                        color: #5B5C64;
                    }
                    label:has(+ input[type="email"]):focus, label:has(+ input[type="email"]):active,
                    label:has(+ input[type="password"]):focus {
                        color: #7A27C9;
                    }
                    label:has(+ input[type="email"]),
                    label:has(+ input[type="password"]) {
                        position: relative;
                        top: 9px;
                        left: 6px;
                        background-color: #FFF;
                        padding: 2px 4px 0px 4px;
                        font-size: 12px; 
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        letter-spacing: .9px;
                        color: #5B5C64 ;
                    }
                    .form-group:focus-within label { 
                        color: #7A27C9;
                        font-weight: 500;
                    }
                    #btn-login{
                        width: 100%;
                        font-size: 16px;
                        background: #8f38e5; 
                        border-radius: 8px; 
                        color: #FFF;
                        padding:6px 8px;
                        display: flex;
                        border: #8f38e5 solid 1px;
                        justify-content: center;
                        margin-top: 16px;
                        cursor:pointer;
                        height: 40px;
                        align-items: center;
                    }
                    .doyou{
                        font-family: Sora,sans-serif;
                        font-size: 14px;
                        padding-top:16px;
                        display: flex;
                        align-items: center;
                        button#btn-signup {
                            background-color: transparent;
                            border: none;
                            color: #8f38e5;
                            cursor:pointer;
                        }
                    }
                  }
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      height: 100%;
                      padding: 40px 74px 44px 0px;
                      line-height: .9;
                  .title {
                      font-size: 24px;
                      font-family: Sora,sans-serif;
                      font-weight: 500;
                  }
                  .error-msg{
                      font-size: 14px;
                      margin: 8px 0 36px 0;
                      font-family: Inter,sans-serif;
                      color: #5B5C64;
                  }
                  .form-group label:focus, .form-group label:active{
                      color: #7A27C9;
                  }
                  .powerd-by {
                      font-size: 11px;
                      display: flex;
                      font-family: $inter-font-family;
                      margin-top: 31px;
                      > img {
                          padding-left: 6px;
                      }
                  }
              }
        } 
    .login-pharma-img > img {
      width: 163px;
    }
}
/* Auth0 styles End */


/* Login styles start */
  .copyRight {
    bottom: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 12px;
    margin-bottom: $spacer * 2;
  }
  .login-grid {
    display: grid;
    grid-template-columns: map-get($grid-col-Bpts, '8-12');
    grid-template-areas: "login-user";
    column-gap: $spacer * 2;
    height: 100%;
    align-items: center;
    width: map-get($dash-body-sec, 'grid-width');
    margin: 0 auto;

      .login-user {
          grid-area: login-user;
          grid-column: 4 / 12;
          height: 434px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          place-items: center;
          background-color: #FFF;
          box-shadow: 0px 2px 8px rgba(46, 47, 53, 0.10); 
          border-radius: $spacer * 2;

            .login-pharma {
                .login-pharma-img {
                    span{
                      font-weight: 500;
                      font-size: 22px;
                      line-height: 1.1;
                    }
                    span:nth-child(1) {
                      color:$purple;
                    }
                    span:nth-child(2) {
                      color: $darkPink;
                    }
                    span:nth-child(3) {
                      font-size: $spacer + 4;
                      font-family: $inter-font-family;
                      margin-top: $spacer;
                    }
                    display: flex;
                    flex-direction: column;
                    > img {
                      width: 274px;
                    }
                }
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                justify-content: space-between;
                padding: $spacer * 5;
            }
            .login-access {
                .title {
                    font-size: $spacer * 3;
                    font-family: $sora-font-family;
                    line-height: .9;
                    font-weight: 600;
                }
                .login-form { 
                      margin-top: 0;//$spacer * 2;
                      margin-right: 77px;
                      .guest-section {
                          margin-top: $spacer * 2;
                          margin-bottom: 0px;
                      }
                    .box-sec {
                        > img:nth-child(1) {
                          height: 42px;
                        } 
                        display: grid;
                        grid-template-columns: 43px auto 20px;
                        height: 74px;
                        width: 396px;
                        padding: $spacer * 2; 
                        background:$white; 
                        border-radius: $spacer; 
                        border: 1px #D9DADE solid;
                        box-shadow:none;
                        .login-title {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            margin-left: $spacer * 2;
                            span:nth-child(1){
                              font-size: $spacer * 2 + 2;
                              font-family: $sora-font-family;
                              color: $purple;
                              line-height: .9;
                            }
                            span:nth-child(2){
                              font-size: $spacer * 2 - 2;
                              font-family: $inter-font-family;
                              line-height: .9;
                            }
                        }
                        .login-arrow {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                        }
                    }
                }
                display: grid;
                width: 100%;
                grid-template-rows: auto 1fr auto;
                grid-auto-flow: row;
                height: 100%;
                align-items: center;
                padding: 40px 0;
                .powerd-by {
                    font-size: 11px;
                    display: flex;
                    font-family: $inter-font-family;
                    > img {
                        padding-left: 6px;
                    }
                }
            }
        }
}
/* Login styles end */

/* Two-factor styles start */
.twofactor-grid {
  display: grid;
  grid-template-columns: map-get($grid-col-Bpts, '8-12');
  grid-template-areas: "twofactor-main";
  column-gap: $spacer * 2;
  height: 100%;
  align-items: center;
  width: map-get($dash-body-sec, 'grid-width');
  margin: 0 auto;

    .twofactor-main {
        grid-area: twofactor-main;
        grid-column: 4 / 12;
        height: 434px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;
        background-color: #FFF;
        box-shadow: 0px 2px 8px rgba(46, 47, 53, 0.10); 
        border-radius: $spacer * 2;

        .twofactor-left {
            .login-pharma-img {
                span{
                  font-weight: 500;
                  font-size: 22px;
                  line-height: 1.1;
                }
                span:nth-child(1) {
                  color:$purple;
                }
                span:nth-child(2) {
                  color: $darkPink;
                }
                span:nth-child(3) {
                  font-size: $spacer + 4;
                  font-family: $inter-font-family;
                  margin-top: $spacer;
                }
                display: flex;
                flex-direction: column;
                > img {
                  width: 274px;
                }
            }
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: space-between;
            padding: $spacer * 5;
      }
      .twofactor-right {
            display: flex;
            flex-direction: column; 
            width: auto;
            margin: 40px 74px;
          .two-fact-mask{
            font-size: 24px;
            padding: 8px 0 0 0;
            text-align: center;
          }
          .title {
              font-size: $spacer * 3;
              font-family: $sora-font-family;
              line-height: .9;
              font-weight: 600;
          }
          .sub-title {
              margin-top: $spacer;
              font-family:$inter-font-family;
              font-size: $spacer * 2;
              color: $text-light-clr;
          }
          .verification-form {
              margin-top: 41px;
              .ver-code{
                font-size: 14px;
                color: $text-light-clr;
                margin-bottom: $spacer;
              }
              .optFrm {
                  display: grid;
                  grid-template-columns: repeat(6, 53px);
                  column-gap: $spacer * 2;
              }
              
              .verfity-btn {
                  margin-top: $spacer * 3;
                  font-size: map-get($font-sizes, 'fs-16');
                  font-weight: map-get($font-weights, 'fw-5');  
                  background-color: #BE84F9;
                  color: $white;
                  width: 100%;
                  height: 40px;
                  border-radius: $spacer;
                  border: #BE84F9 solid 1px; 
              }
              .verfity-btn.active{
                  background-color: $phone-bg-clr;
                  border: $phone-bg-clr solid 1px; 
              }
              .optFrm {
                  .input-boxes {
                      input{
                          border-left: none;
                          border-top: none;
                          border-right: none;
                          border-radius: 0px;
                          border-bottom: #B7B9C2 solid 2px;
                      }
                      input:active, input:focus{
                          border-bottom: #7A27C9 solid 2px;
                          background-color: #F3E9FE;
                      }
                  }
              }
              .text-blue {
                  margin-top: $spacer;
                  a { 
                    font-family: $sora-font-family;
                    color: #7A27C9;
                    font-size: $spacer * 2 - 2;
                    text-decoration: none;
                  }
              }
          }
          .powerd-by {
              margin-top: 36px;
              font-size: 11px;
              display: flex;
              font-family: $inter-font-family;
              > img {
                  padding-left: 6px;
              }
          }
          .txt-clr-prpl{
            color:#7A27C9;
          }
      }
    }

  }
/* Two-factor styles end */
main {
  grid-column: 4 / span 8; 
}

.myPrxlogo{ 
  width: 112px;
}
.menu{ 
  width: 28px;
  height: 28px;
  border-radius:0;
}
.bell-icon{ 
  width: 28px;
  height: 28px;
  margin-left: $spacer + 4;
  cursor: pointer;
}
.border_radius_50{
  border-radius: 50%;
}
.ntf-icon{
  cursor: pointer;
}

.menu-icon, .logo {
  justify-self: start;  
}
.logo {
  padding-left: $spacer * 3;
}

.heading--strip {
  grid-area: heading-strip;
  max-width: 100%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); 
  border-image: linear-gradient(286deg, #FF98DB 0%, #FFE3F6 100%);
  border: 1px $white solid;
  border-radius: 8px;
  grid-column: 3 / span 10;
  display: grid;
  overflow: hidden;
  grid-template-columns:3fr max-content min-content;
  height: 88px;
  background-image: url("/assets/images/desktopbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  padding: 16px 24px;
}

.comp-phone {
  padding-right: $spacer * 3;
  .tooltip-clip{
    display: none; 
    position: absolute; 
    background-color: black; 
    color:$white; 
    padding: 5px; 
    border-radius: 4px; 
    font-size: 11px;
    width: 150px;
    z-index: 999;
  } 
}

.phrmaLogo-bg{
  height: 56px;
}
.PhNum{
  font-size: 14px;
  font-weight: map-get($font-weights, 'fw-6');
  background: $phone-bg-clr; 
  border-radius: $spacer; 
  color: $white;
  padding:$spacer - 4 $spacer - 2;
  display: flex;
  a {
      text-decoration: none;
      color: $white;
  }
}

.PhNum > span {
  padding-right: 5px;
  font-size: 20px;
}

main {
  grid-area: main;
  grid-column: 3 / 13;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(3, 80px);
  column-gap: $spacer * 2;
  row-gap: $spacer * 2;
  margin-top: $spacer * 4;
  column-gap: 16px;
}

.box{
  height: 80px;  
  box-shadow: 0px 2px 8px #EAEAEB;
  background: $white;
  border-radius: $spacer;
  padding: ($spacer * 3) - 4 $spacer * 3;
  cursor: pointer;
  .left-box {
    display: flex;
    justify-content: center;
  }
  .left-box > img {
      width: 32px;
  }
  .middle-box {
    flex-grow: 1; 
    font-size: 18px; 
    font-weight: map-get($font-weights, 'fw-4');
    padding-left: $spacer + $spacer;
  }
  .right-box {
    background: $right-bg-clr;
    border-radius: 100px;
    width: 50px;
    height: 40px;
    color: $box1;
    font-size: 18px; 
    font-weight: map-get($font-weights, 'fw-6');
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--1 {
    grid-area: 1 / 1 / 2 / 5;
    border-left: 3px $box1 solid;
    display: flex;
    align-items: center;
  }
  &--2 {
    grid-area: 1 / 5 / 2 / 9;
    border-left: 3px $box2 solid;
    display: flex;
    align-items: center;
    .right-box {
      background: $right2-bg-clr; 
      color: $box2;
    }
  }
  &--3 {
    grid-area: 2 / 1 / 3 / 5;
    border-left: 3px $box3 solid;
    display: flex;
    align-items: center;
    .right-box {
      background: $right3-bg-clr; 
      color: $box3;
    }
  }
  &--4 {
    grid-area: 3 / 1 / 5 / 5;
    border-left: 3px $box4 solid; 
    display: flex;
    align-items: center;
    .right-box {
      background: $right4-bg-clr;
      color: $box4-color;
    }
  }
  &--5 {
    grid-area: 2 / 5 / 3 / 9;
    border-left: 3px $box5 solid; 
    display: flex;
    align-items: center;
    .right-box {
      background: $right5-bg-clr;
      color: $box5;
    }
  }
  &--6 {
    grid-area: 1 / 9 / 4 / 11;
    background-color: $right6-bg-clr;
    border-radius: $spacer;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-row-gap: .3rem;
    align-items: center;
    padding: 24px;
    box-shadow: none;
  }
  &--bg{
    color: $rightclr;
    font-size: 18px;
    font-family: Sora;
    font-weight: map-get($font-weights, 'fw-6');
    width: 100%;
  }
  .bannerGirl-bg{
    width: 128px;
  }
}
.comp-address {
  line-height: 20px;
}
.comp-address .title,
.comp-address .address {
  color: $text-color;
  word-wrap: break-word;
}

.comp-address .title {
  font-size: 18px;
  font-weight: map-get($font-weights, 'fw-5');
}

.comp-address .address {
  font-family: $inter-font-family;
  font-size: 14px;
  font-weight: map-get($font-weights, 'fw-4');
}

.comp-address .address {
  text-transform: capitalize;
}
.heading--text{
  grid-area: heading-text;
  margin: ($spacer * 2 - 3) 0 $spacer * 2 0;
  grid-column: 3 / span 10;
  display: flex;
  align-items: center;
  color: $text-color;
  font-size: 22px;  
  font-weight: map-get($font-weights, 'fw-5');
}
.hb-icon {
  padding-left: $spacer * 2;
  height: 52px;
}

/* Profile section */
.profile {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: map-get($grid-col-Bpts, '8-12');
  grid-template-areas:
      "user-img" 
      "user-data"
      "profile_data";
  column-gap: 16px; 
  margin: 0 auto;
  width: map-get($dash-body-sec, 'grid-width');
}
.binpcn{
  color: #5B5C64;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: map-get($font-weights, 'fw-4');;
}
.my-account{
  height: 93px;
  display: grid;
  width: 100%;
  background: linear-gradient(123deg, #F3E9FE 0%, #FFE3F6 100%);
  .title{
    column-gap: $spacer * 2;
    display: grid;
    grid-template-columns: 24px repeat(12, 1fr) 24px;
    min-width: map-get($dash-body-sec, 'grid-width');
    margin: 0 auto;
    span{
      grid-column: 3 / span 10;
      font-weight: map-get($font-weights, 'fw-6');
      font-size: $spacer * 2;
      padding-top: $spacer * 3;
    }
  }
}

.user-img {
  grid-area: user-img;
  grid-column: 3 / span 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-row: 1 / 1;
  margin-top: -39px;

  .profileImg{
    border-radius: 9999px;
    border: 1px white solid;
    width: 130px; 
    height: 130px;
  }

  .profile_edit{
    border-radius: 50%;
    color: #7a27c9;
    background-color: #fff;
    padding: 3px;
    position: relative;
    right: 28px;
    top: -10px;
    cursor: pointer;
  }
}

.user-info{
  padding-top: $spacer * 5;

  .user-name{
    color: $text-color;
    font-size: 18px; 
    font-weight: map-get($font-weights, 'fw-5');
  }
  .user-age{
    color: #5B5C64; 
    font-size: 14px; 
    font-family: Inter; 
    font-weight: map-get($font-weights, 'fw-4');
    margin-top: 5px;
  }
}
.userdata{
  grid-area: user-data;
  display: grid;
  grid-column: 3 / 13;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: auto;
  row-gap: $spacer * 2;
  column-gap: $spacer * 2;    
  margin-top: 32px;
  padding-bottom: 20px;
}
#ngb-live {
  display: none;
}
.userInfo{
  border-radius: 8px;
  border-top: 2px $purple solid;
  box-shadow: 0px 2px 8px rgba(46, 47, 53, 0.10);
  background: $white;
  grid-column: span 5;
  padding:24px 30px;
  display: flex;
  flex-direction: column;

  /* profile data styles start */

  .title {  
    color: $purple;
    font-size: 14px;
    font-weight: map-get($font-weights, 'fw-6');
    line-height: 20px;
    word-wrap: break-word;
    padding-bottom: $spacer * 2;
    display: flex;
    .add_insurance{
      padding-left: 10px;
      font-size: 23px;
      color: #5c5555;
      cursor: pointer;
    }
  }
  .info {
    left: 0px;
    top: 36px;
    display: flex;
    flex-direction: column;
  }
  .info-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 0 12px 0; 
    border-bottom: 1px solid #F3E9FE;
    border-top: 1px solid #F3E9FE;
    font-size: 14px;
    font-family: Inter;
    font-weight: map-get($font-weights, 'fw-4');
    color: #5B5C64;
  }
  .info-value {
    color: $text-color;
    font-size: 14px;
    font-family: Inter;
    font-weight: map-get($font-weights, 'fw-5');
    word-wrap: break-word;
    text-align: right;
    color: #2E2F35;
  }
  .margin_top_24px{
    margin-top: 24px !important;
  }
  .material-symbols-outlined {
    cursor: pointer;
    font-size: 24px;
    color: #5F6368;
    transition: color 0.3s;
  }
  
  .material-symbols-outlined.disabled {
    cursor: not-allowed;
    color: #ccc;
  }  
  #insuranceNoScroll {
    overflow-y: unset;
    width: 99.99%;
    padding: 0 0 24px 24px;
}
.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

.dot {
  height: 5px;
  width: 5px;
  margin: 0 5px;
  background-color: lightgray;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #7a27c9;
  width: 21px;
  height: 5px;
  border-radius: 100px;
}
.input_disabled {
  background-color: #f0f1f5 !important;
  cursor: not-allowed !important;
  color: #7b7b80 !important;
  border: 1px solid #ccc !important;
  pointer-events: none !important;
}

.input_disabled {
    outline: none !important;
}
.error-field {
  border: 1px solid red !important;
}
.mobile-only{
  display: none !important;
}
  .carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
  }
  
  .insu_info{
      width: 333px;
      height: 209px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 13px;
      margin: 0 10px;
      background-image: url("/assets/images/card1_insu.png");
      display: grid;
      padding: 12px;
      z-index: 8;
      grid-template-columns: repeat(3,1fr);
      grid-template-rows: repeat(4,auto);
          .insu_info_header {
            grid-area: 1 / 1 / 2 / 4;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: 12px;
        }
        .insu_info_name {
            grid-area: 2 / 1 / 3 / 4;
        }
        .insu_info_id {
          grid-area: 3 / 1 / 4 / 4;
          display: grid;
          // grid-template-columns: repeat(3, 100px);
          margin-top: -15px;
        }
        .insu_info_bin {
          grid-area: 4 / 1 / 4 / 4;
      }
      .insu_info_id1 {
        grid-area: 3 / 1 / 4 / 2;
      }
      .insu_info_id2 {
        grid-area: 3 / 2 / 4 / 3;
      }
      .insu_info_id3 {
        grid-area: 3 / 3 / 4 / 4;
        background-color: #7a27c9;
        border-radius: 4px;
        padding-top: 5px;
        width: 100%;
        height: 25px;
        font-size: 10px;
        color: #fff;
        text-align: center;
      }
      .font_size_data{
        font-size: 12px;
        color: #2E2F35;
        font-weight: 600;
      }
      .font_size_data1{
        display: flex;
        font-size: 10px;
        color: #2E2F35;
        font-weight: 500;
      }
      .insu_call{
        font-size: 16px;
        margin: -2px 2px;
        font-weight: 600;
        color: black;
      }
      .font_size_data2{
        font-size: 9px;
      }
  }
    /*Add Insurance Popup Styles Start */
    .insupopup-backdrop{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1000;
    }
    .insupopup_modal{
      display: grid;
      grid-area: insupopup_modal;
      grid-column: 4 / 9;
      position: relative;
    }
    .insupopup_modal_container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: white;
      border-radius: 8px;
      width: 1100px;
      max-width: 90%;
      height: auto;
      padding: 8px;
      z-index: 1001;
      text-align: center;
    }
    .insupopup_header{
      display: flex;
      justify-content: space-between;
      p{
        color: #8f38e5;
        font-weight: 600;
        font-size: 16px;
        margin: 24px;
      }
      button{
        border-radius: 50%;
        border: none;
        height: 24px;
        width: 24px;
        background-color: #545151;
        color: white;
        margin: -15px -15px 0 0;
        cursor: pointer;
      }
    }
    .insupopup_footer{
      display: flex;
      justify-content: flex-end;
      padding: 16px;
      .insu_buttons{
        width: 150px;
        border-radius: 8px;
        padding: 8px 16px 8px 16px;
        cursor: pointer;
      }
    }
    .insupopup_footer button:nth-of-type(1) {
      background-color: white;
      color: #8f38e5;
      margin-right: 10px;
      border: 1px solid #8f38e5;
    }
    .insupopup_footer button:nth-of-type(2) {
      background-color: #8f38e5;
      color: white;
      border: 1px solid #8f38e5;
    }
    .insu_data{
      display: grid;
      grid-template-columns: repeat(4, 200px);
      grid-template-rows: repeat(2, 100px);           
    }
    .insupopup_body input[type=text],
    .insupopup_body input[type=tel],
    .insupopup_body select {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      border: 1px solid #bfbfbf;
      background: #fff;
      font-family: var(--font-family);
      font-size: 14px;
      font-weight: 400;
      color: var(--black-color);
      padding-left: 8px;
      margin-left: 10px; /* Space between label and input */
    }
    .insupopup_body  label:has(+input[type=text]),label:has(+input[type=tel]),label:has(+select) {
      position: relative;
      top: 6px;
      left: 15px;
      background-color: #fff;
      padding: 2px 4px;
      font-size: 11px;
      color: var(--black-color);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0px;
      height: 15px;
      white-space: nowrap;
      display: flex;
      width: fit-content;
  }
  .insupopup_body .dropdown-control {
    width: calc(100% - 0px);
    height: 40px;
    border-radius: 8px;
    border: 1px solid #bfbfbf;
    background: #fff;
    font-family: var(--font-family);
    font-size: 14px;
    color: var(--black-color);
    padding: 0 8px;
    box-sizing: border-box;
    option{
      font-size: 10px;
    }
}
.insurance-list {
  margin-top: 10px;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
}
.data_check_exists {
  border: 1px solid rgba(255, 60, 0, .2);
    background: rgba(255, 60, 0, .1019607843);
    width: 420px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-left: 10px;
    color: red;
    span{
      color: red;
      padding-right: 10px;
    }
}
.data_check_inactive{
    width: 550px !important;
}
.insurance-list table {
  width: 100%;
  border-collapse: collapse;
}

.insurance-list th,
.insurance-list td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.insurance-list th {
  background-color: #f4f4f4;
}

.insupopup_body ::placeholder {
  color: gray; 
  font-size: 10px;
}
.label_text small{
  color: red;
}
  .left_label_bin_pcn{
    left: -80px !important;
  }
  .left_label_priority{
    left: -65px !important;
  }
  .insupopup_body {
    display: grid;
    grid-template-columns: repeat(4, 250px);
    grid-template-rows: repeat(2, 60px);
    gap: 10px;
    margin-bottom: 10px;
    .dropdown-menu {
      padding: 0px;
      margin: 0px;
      border-right: 1px solid #dddddd;
      cursor: pointer;
      &.show {
        width: 502px;
        overflow-y : scroll;
        max-height: 140px;
        z-index: 1000;
        border-bottom: 0px;
        box-shadow: 0px 0px 6px #c7c3c3;
        .no-result {
          background-color: #f3eada;
          color: #000;
          height: 75px;
          display: grid;
          grid-template-columns: 4fr 1fr;
          justify-items: center;
          align-items: center;
          p{
            font-size: 14px;
            padding-left: 25px;
          }
          b{
            color:#8f38e5;
          }
        }
      }
    }
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0px;
      overflow-x: auto;
    }
    .dropdown-item{
      background-color: white;
      border: 1px solid #ededed;
      border-bottom: 0px;
      width: 100%;
      padding: 0;
    }
}
.insurance_dropdown{
      display: grid;
      grid-template-columns: 1fr 20% 20%;
      grid-template-rows: 1fr;
      gap: 10px;
      background-color: #fff;
      margin-left: 0px;
      padding: 12px;
      border-bottom: 1px solid #D9DADE;
    .card{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .card-1,
      .card-2,
      .card-3 {
        span:nth-child(1) {
          font-size: 12px;
          color: #5B5C64;
          font-weight: 400;
        }
        span:nth-child(2) {
          font-size: 14px;
          color: #2E2F35;
          font-weight: 400;
          display: flex;
          justify-content: flex-start;
          text-align: left;
        }
      }
}
.custom-dropdown select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff url('data:image/svg+xml;base64,<your-custom-arrow-svg>') no-repeat right 10px center;
}

.custom-dropdown option {
  background-color: #fff;
  color: #333;
  font-size: 10px;
}

.custom-dropdown option:hover {
  background-color: #f0f0f0;
}

.add_button{
  background-color: #fff;
    color: #8f38e5;
    border: 1px solid #8f38e5;
    width: 55px;
    height: 30px;
    font-weight: 500;
    border-radius: 10px;
}
  /* profile data styles end */

  &--1 {
    grid-row: span 3;
  }
  &--2 {
    grid-row: span 3;
    padding:0px;
  }
  &--3 {
    grid-row: span 3;  
  }
  &--4 {
    grid-row: span 3;
  }
}
#insuranceScroll{ 
  overflow-y: auto;
  max-height: 270px;
  width: 99.99%;
}
#insuranceScroll .title {
  position: -webkit-sticky;  
  position: sticky;
  top: 0;
  background-color:$white;  
  z-index: 9;
  padding-top: 24px;
}
#insuranceScroll::-webkit-scrollbar { 
  width: 5px;
}
#insuranceScroll::-webkit-scrollbar-track {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #EEEEF0;
  border: 1px solid #EEEEF0;
}
#insuranceScroll::-webkit-scrollbar-thumb {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #B7B9C2;
}

/* Pending Payment start */

.revieworder {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 24px repeat(12, 1fr) 24px;
  grid-template-areas:
      "app-banner"
      "review-order"
      "order-place";
  column-gap: 16px;
  margin: 0 auto;
  width: map-get($dash-body-sec, 'grid-width');

    app-banner {
      grid-area: app-banner;
      grid-column: 3 / 13;
      padding: $spacer * 4 0 $spacer * 3 0;
    }
    
    .review-order{
      grid-area: review-order;
      grid-column: 3 / 13;
      display: grid;
      
      .title {
          margin-bottom: $spacer;
      }
      .first-record-top {
          box-shadow: 0 -2px 8px 0px rgba(46, 47, 53, 0.10);
          border-top-right-radius: $spacer;
          border-top-left-radius: $spacer;
          border-bottom: #DFE4E9 solid 1px;
      }
      
      .last-record-bottom {
          box-shadow: 0 2px 4px 0px rgba(46, 47, 53, 0.10);
          border-bottom-right-radius: $spacer;
          border-bottom-left-radius: $spacer;
      }
      
      .single-item {
        box-shadow: 0px 2px 8px 0px rgba(46, 47, 53, 0.10);
        border-radius: $spacer;
      }
      .middle-record {
          box-shadow:0 15px #fff,0 -15px #fff,0 2px 0px #2e2f351a,0 -2px 4px #2e2f351a;
          border-bottom: #DFE4E9 solid 1px;
          border-top: #DFE4E9 solid 1px;
          border-radius: unset;
      }
    }
    .order-place{
      grid-area: order-place;
      grid-column: 3 / 13;
      display: grid;

      .section {
          margin-top: 24px;
          display: grid;
          grid-template-columns: auto max-content;
          align-items: end;
          align-content: center;

        .sec{
          &--1{            
              .amt-label {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  font-size: map-get($font-sizes, 'fs-16');

                  > span{
                    font-size: map-get($font-sizes, 'fs-20');
                    font-weight: map-get($font-weights, 'fw-7');
                    color: $phone-bg-clr;
                    margin-left: $spacer;
                  }
              }
          }
          &--2{
            display: flex;
            justify-content: flex-end;
            margin-left: $spacer * 2;
            button{
              display: flex;
              align-items: center;
              height: 36px;
              border: none;
              border-radius: $spacer;
              padding: $spacer $spacer * 2;
            }
            .cancel{
              border: $phone-bg-clr solid 1px;
              color: $phone-bg-clr;
              background: $white;
            }
            .pay-now {
              font-size: map-get($font-sizes, 'fs-14');
              font-weight: map-get($font-weights, 'fw-5');  
              background: $phone-bg-clr;
              color: $white;
              margin-left: $spacer;
            }
          }
        }
      }
    }

    .order-list {
        display: grid;
        grid-template-columns: 10% 15% 15% 15% auto;
        grid-template-rows: max-content;
        padding: $spacer * 2;
        background: $white;
        align-items: center;
        
        .item {
            display: flex;
            flex-direction: column;
        }
        .item > span:first-child {
            color: $text-light-clr;
            font-size: map-get($font-sizes, 'fs-12');
            font-family: $inter-font-family;
            font-weight: map-get($font-weights, 'fw-4');
        }
        .item > span+span {
            color: $text-color;
            font-size: map-get($font-sizes, 'fs-14');
            font-weight: map-get($font-weights, 'fw-5');
            font-family: $sora-font-family;
            line-height: normal;
            word-wrap: break-word;
        }

        .item > img.drug-img {
            width: 70%;
            aspect-ratio: 3 / 2;
            object-fit: contain;
            border-radius: 4px;
        }
    }
}
/* Pending Payment end */

/* Payment Confirm start */
  .paymentConfirm {
      display: grid;
      grid-template-rows: auto auto 1fr;
      grid-template-columns: 24px repeat(12, 1fr) 24px;
      grid-template-areas:
          "app-banner"
          "payment-order";
      column-gap: 16px;
      margin: 0 auto;
      width: map-get($dash-body-sec, 'grid-width');

      app-banner {
        grid-area: app-banner;
        grid-column: 3 / 13;
        padding: $spacer * 4 0 $spacer * 3 0;
      }
      
      .payment-order {
        grid-area: payment-order;
        grid-column: 3 / 13;
        display: grid;
        
        .title {
            margin-bottom: $spacer;
        }
        .payment-list { 
            padding-top: 42px;
            box-shadow: 0px 2px 8px 0px rgba(46, 47, 53, 0.10);
            border-radius: $spacer;
            background-color: $white;
            .payment-succ{
                display: flex;
                justify-content: center;
                img {
                  width: auto;
                  height: 94px;
                }
            }
            .payment-out {
                display: grid;
                justify-items: center;
                grid-template-columns: 1fr;
                grid-template-rows: repeat(9, max-content);
                margin: 0 auto;
                width: 458px;
                
                .text{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .item--1 {
                    margin-bottom: $spacer + 5;
                    > span {
                      color: #039D00;
                      font-size: 20px;
                    }
                }
                .item--2 {
                    margin-bottom: $spacer * 3 - 4;
                    span { 
                        font-size: $spacer * 2;
                        font-family: $inter-font-family;
                        font-weight: normal; 
                    }
                }
                .item--3 {
                    border-top: #DFE4E9 solid 1px;
                    height: 50px;
                }
                .item--8 { 
                  span + span {
                    font-weight: 600;
                  }
                }
                .item {
                    display: grid;
                    grid-auto-flow: column;
                    width: 100%;
                    justify-content: space-between;
                    align-items: end;
                    
                    > span {
                        color: $text-light-clr;
                        font-family: $inter-font-family;
                        font-size: $spacer * 2;
                    }
                    span + span {
                      color: $text-color;
                      font-family: $sora-font-family;
                      font-size: $spacer * 2 + 2;
                  }
                }
                .payments {
                    width: 100%;
                    .pay-btns {
                      display: flex;
                      margin: $spacer * 6 0 ($spacer * 7) + 3 0;
                      button{
                        display: flex;
                        align-items: center;
                        height: 36px;
                        border: none;
                        border-radius: $spacer;
                        padding: $spacer $spacer * 2;
                        width: 100%;
                        justify-content: center;
                      }
                      .cancel{
                        border: $phone-bg-clr solid 1px;
                        color: $phone-bg-clr;
                        background: $white;
                      }
                      .pay-now {
                        font-size: map-get($font-sizes, 'fs-14');
                        font-weight: map-get($font-weights, 'fw-5');  
                        background: $phone-bg-clr;
                        color: $white;
                        margin-left: $spacer;
                      }
                    }
              }
            }
        }
      }
  }
/* Payment Confirm end*/

/* RxHistory, My Meds, Ready to Dispense, Start */ 
.history {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: map-get($grid-col-Bpts, '8-12');
  grid-template-areas:
      "app-banner"
      "bread-crumbs" 
      "history-data";
  column-gap: $spacer * 2; 
  margin: 0 auto;
  width: map-get($dash-body-sec, 'grid-width');
}

.bread-crumbs{
  grid-area: user-img;
  grid-column: 3 / span 10;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  column-gap: $spacer;
  scroll-behavior: smooth;
  
  button{
    border-radius: $spacer;
    background-color: #FFFFFF;
    border: #B7B9C2 solid 1px;
    display: flex;
    align-items: center;
    padding: $spacer 24px;
    font-size: $spacer * 2;
    height: $spacer * 5 + 4;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: max-content;
    span{
      display: flex;
      align-items: center;
    }
  }
  button.active { 
      align-self: center;
  }
  button:hover{
    margin-bottom: 0px;
    padding-bottom: 6px;
  }
  span{
    background-color: $pending-pay-clr;
    border-radius: 100px; 
    min-width: 38px;
    height: 28px;
    color: $white;
    padding: 3px;
    margin-left: 12px;
    display: flex;
    justify-content: center;
  }
  .pending{
    color: $pending-pay-clr; 
    > span{
      background-color:$pending-pay-clr; 
    } 
  }
  .pending:hover{
    border: 1px solid $pending-pay-clr;
    border-bottom-width: 3px;
  }
  .pending.active, .pending:focus{
    padding-top: $spacer - 2;
    padding-bottom: $spacer - 2;
    background-color:$pending-pay-clr;
    color: $white;
    border: $pending-pay-clr solid 1px;
    span{
      background-color: $white;
      color: $pending-pay-clr;
      padding: 3px;
      margin-left: 12px;
    }
  }
  
  .meds{
    color:$myMeds-clr; 
    > span{
      background-color:$myMeds-clr; 
    }
  }
  .meds:hover{
    border: 1px solid $myMeds-clr;
    border-bottom-width: 3px;
  }
  .meds.active, .meds:focus{
    padding-top: $spacer - 2;
    padding-bottom: $spacer - 2;
    background-color: $myMeds-clr;
    color: $white;
    border: $myMeds-clr solid 1px;
    span{
      background-color: $white;
      color: $myMeds-clr;
      padding: 3px;
      margin-left: 12px;
    }
  }

  .ready{
    color: $ready-clr;
    > span{
      background-color: $ready-clr; 
    }
  }
  .ready:hover{
    border: 1px solid $ready-clr;
    border-bottom-width: 3px;
  }
  .ready.active, .ready:focus{
    padding-top: $spacer - 2;
    padding-bottom: $spacer - 2;
    background-color: $ready-clr;
    color: $white; 
    border: $ready-clr solid 1px;
    span{
      background-color: $white;
      color: $ready-clr;
      padding: 3px;
      margin-left: 12px;
    }
  }


  .new{
    color: $new-Pre-clr; 
    > span{
      background-color: $new-Pre-clr; 
    }
  }
  .new:hover{
    border: 1px solid $new-Pre-clr;
    border-bottom-width: 3px;
  }
  .new.active, .new:focus{
    padding-top: $spacer - 2;
    padding-bottom: $spacer - 2;
    background-color: $new-Pre-clr;
    color: $white; 
    border: $new-Pre-clr solid 1px;
    span{
      background-color: $white;
      color: $new-Pre-clr;
      padding: 3px;
      margin-left: 12px;
    }
  }


  .refills{
    color: $refillDue-clr; 
    > span{
      background-color: $refillDue-clr; 
    }
  }
  .refills:hover{
    border: 1px solid $refillDue-clr;
    border-bottom-width: 3px;
  }
  .refills.active, .refills:focus{
    padding-top: $spacer - 2;
    padding-bottom: $spacer - 2;
    background-color: $refillDue-clr;
    color: $white; 
    border: $refillDue-clr solid 1px;
    span{
      background-color: $white;
      color: $refillDue-clr;
      padding: 3px;
      margin-left: 12px;
    }
  }
}

.history-data{
  grid-area: history-data;
  grid-column: 3 / span 10; 
}
.authSection {
  position: sticky;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  background-color: #f9f9f9;
  bottom: 0;
  padding-top: $spacer * 2;
  button {
    font-size: 14px;
    font-weight: 500;
    background: $phone-bg-clr;
    border-radius: $spacer;
    border: none;
  }
}
app-banner {
  grid-area: app-banner; 
  grid-column: 3 / 13;
  padding-top: 0;
}
.history {
  app-banner {
      grid-area: app-banner; 
      grid-column: 3 / 13;
      padding: $spacer * 4 0 $spacer * 4;
  }
  .bread-crumbs { 
    grid-area: bread-crumbs; 
    grid-column: 3 / 13;
  }
  
  .history-data {
    grid-area: history-data;
    grid-column: 3 / 13;
    position: relative; 

      .title{
        font-size: $spacer * 2;
        font-weight: map-get($font-weights, 'fw-6'); 
        color: $text-light-clr;
        display: flex;
        justify-content: space-between;
        align-items: center; 
      }
      .filterMenu{
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        font-family: $inter-font-family;
      }
      .filterMenu::after {
          font-family: $google-fonts;
          content: "\ef4f";
          font-weight: map-get($font-weights, 'fw-3');
          font-size: 17px;
      }  
    .hist-switch {
        max-height: 640px;
        height: 100%;
        .sec0 > .title{
          visibility: hidden;
        }
        .sec1 > .title{
          font-size: $spacer * 2;
          margin-top: $spacer * 3;
          font-weight: map-get($font-weights, 'fw-6'); 
          color: $text-light-clr;
          display: flex;
          justify-content: space-between;
          align-items: center; 
        }
        .sticky-header {
          position: -webkit-sticky;
          position: sticky;
          right: 0;
          top: 0;
          z-index: 10;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          background-color: #F9F9F9;
          height:45px; 
        }
      position: relative; 
    }
    .pending-payments{
        margin-top: $spacer * 3;
        padding-bottom: $spacer;
        .mymeds .label {
            display: grid;
            grid-template-columns: 23% 22% 22% auto auto;
            grid-template-rows: max-content max-content;
            row-gap: $spacer;
            align-content: space-between;

            .meds-1 { 
              grid-area: 1 / 1;
            }
            .meds-2 { 
              grid-area: 1 / 2;
            }
            .meds-3 { 
              grid-area: 1 / 3;
            }
            .meds-4 { 
              grid-area: 1 / 4;
            }
            .meds-5 {
              grid-area: 1 / 5;
              align-items: flex-end;
            }

            button.btn-pending {
                background-color: $btn-pending;
                border: none;
                border-radius: $spacer;
                color: $white;
                font-size: $spacer * 2;
                width: 112px;
                padding: $spacer $spacer * 2;
                height: 36px;
                display: flex;
                align-items: center;
            }
            .pending-amt{
              color: $amount-clr;
              font-size: $spacer * 2;
              font-weight: 500;
            }
        }
        
        span.text-small {
            background-color: $pending-time;
            border-radius: 4px;
            font-size: 11px;
            font-weight: 500;
            padding: 3px 4px;
        }
        .item.active {
            border: none;
        }
        .item.active .label { 
            height: 37px;
        }
        .item .label {
            cursor: text;
        }
        .item .label:before {
            content: "";
        }
    }
    .ready-dispense {
        margin-top: $spacer * 3;
        span.dispenseMenu::after {
            display: none;
        }
      .mymeds.active {
          border: $ready-clr solid 1px;
      }
    }
    .new-prescription {

      .mymeds .label {
        display: grid;
        grid-template-columns: 21% 20% 30% 1fr;
        grid-template-rows: max-content max-content;
        row-gap: $spacer;
        align-content: space-between;
      }
      margin-top: $spacer * 3;
      .item.active {
        border: none;
      }
      .item.active .label { 
        height: 37px;
      }
      .item .label {
        cursor: text;
      }
      .item .label:before {
        content: "";
      }
    }

    /* refillDue start */
    .refills-due{

      .refillItems{
          display: flex;
          justify-content: space-between;
          margin-right: $spacer * 3;  
      }
      .mymeds {
        
          .label:before { 
            top: 7px;
          }

          .authStatus {
              position: absolute;
              right: 0;
              bottom: 0;
              border-radius: $spacer * 6 + 2;
              padding: 2px 13px;
              display: flex;
              align-items: center;
              font-family: $inter-font-family;
              font-size: 12px;
              font-weight: 500;
              height: auto;
          }
          .brownClr{
            background-color: #FEEBC3;
            color: #715212;
          }
          .greenClr{
            background-color: #A7F3DA;
            color: #065F42;
          }
      }
    }
    /* refillDue end */
  }
    /* History My Meds screen start */
    .mymeds {
      border-radius: $spacer;
      padding:16px 12px 16px 24px;
      background: $white; 
      box-shadow: 0px 2px 8px #eaeaeb; 
      border-radius: $spacer;
      margin-top: $spacer;
      
        span {
          height: 18px;
        } 
        .label {
            display: grid;
            grid-template-columns: 20% 21% 32% 1fr;
            grid-template-rows: max-content max-content; 
            row-gap: $spacer;
            align-content: space-between;
        } 
        .meds-1 { 
          grid-area: 1 /1;
        }
        .meds-2 { 
          grid-area: 1 /2;
        }
        .meds-3 { 
          grid-area: 1 /3;
        }
        .meds-4 { 
          grid-area: 1 / 4;
        }
        .meds-5 {
          grid-area: 2 / 1;
        }
        .meds-6 {
          grid-area: 2 / 2; 
        }
        .meds-7 {
          grid-area: 2 / 3; 
        } 
        .meds-8 {
          grid-area: 2 / 4;
        }
    }
  /* History My Meds screen end */

  /* Ready To Dispense Tab Start */
  .ready-dispense{

    .label {
        display: grid;
        grid-template-columns: 29% 39% 1fr;
        grid-template-rows: max-content max-content;
        row-gap: 8px;
        align-content: space-between;
    }
    .meds-1 { 
      grid-area: 1 / 1;
    }
    .meds-2 { 
      grid-area: 2 / 1;
    }
    .meds-3 { 
      grid-area: 1 / 2;
    }
    .meds-4 { 
      grid-area: 2 / 2;
    }
    .meds-5 {
      grid-area: 1 / 3;
    }
    .meds-6 {
      grid-area: 2 / 3; 
    }
  }
  /* Ready To Dispense Tab End */ 


  /* refillsDue Tab Start */ 
  .refills-due{
      .mymeds.active {
        border: $refillDue-clr solid 1px;
      }
      .title {
        display: flex;
        align-items: flex-end;
        height: 48px; 
      }
    .label {
        display: grid;
        grid-template-columns: 29% 30% 1fr;
        grid-template-rows: max-content max-content;
        row-gap: 8px;
        align-content: space-between;
    }
    .meds-1 { 
      grid-area: 1 / 1;
    }
    .meds-2 { 
      grid-area: 2 / 1;
    }
    .meds-3 { 
      grid-area: 1 / 2;
    }
    .meds-4 { 
      grid-area: 2 / 2;
    }
    .meds-5 {
      grid-area: 1 / 3;

      input#auth { 
        position: absolute;
        top: 0;
        right: 23px;
      }
    }
    .meds-6 {
      grid-area: 2 / 3; 
    }
  }
  /* refillsDue Tab End */ 

  .mymeds.active {
    border: $myMeds-clr solid 1px;
  }
	.mymeds .meds {
		display: flex;
		flex-direction: column; 

    > span:first-child{
      color: $text-light-clr; 
      font-size: 12px;  
      font-family: $inter-font-family; 
      font-weight: map-get($font-weights, 'fw-4');
    }
    > span + span {
      color: $text-color; 
      font-size: 14px; 
      font-family: $sora-font-family; 
      font-weight: map-get($font-weights, 'fw-5');
      line-height: normal;
    }
	} 
}
/* History End */
/* scroll bar start */
.mobile-common-scroll{
  overflow-y: auto;
  padding-right: 5px;
}
.mobile-common-scroll::-webkit-scrollbar{  
  width: 6px;
}
.mobile-common-scroll::-webkit-scrollbar-track{
  margin-top: $spacer * 6;
  background-color: #EEEEF0;
  border: 1px solid #EEEEF0;
  border-radius: 15px;
}
.mobile-common-scroll::-webkit-scrollbar-thumb{
  background-color: #B7B9C2; 
  border-radius: 15px;
  height: 100px;
} 
/* scroll bar end*/
/* mobile-common-scroll scroll bar start */
.mobile-common-title {
  padding-top: 0;
  background-color: #f9f9f9;
  position: sticky;
  top: 0;
  z-index: 10;
}
/*mobile-common-scroll scroll bar end */

@media (max-width: 1440px) {
    .login-grid {
          .login-user{
            .login-access{
              .login-form{
                  .box-sec{
                    width: 100%;
                  } 
              } 
          }
      }
    }
    .head-nav {
        border-color: $green;
        width: 100%;
        max-width: none;  
        box-shadow: 0px 2px 8px 0px $hb-shdw;
        border-bottom: 1px $hb-shdw solid;
        background: $white;
        min-width: 100%;
    }
    .wrapper, .profile, .history, .revieworder, .paymentConfirm, .login-grid, .twofactor-grid, .auth0-login-grid, .sub-alert, .spc-container{ 
      width: 100%;
    }
    .my-account .title {
      min-width: 100%; 
    }
        .bread-crumbs:before {
            content:"";
            position: absolute;
            width: 78%;  
            height: 5.8%;
            background: linear-gradient(to left, rgb(255 255 255 / 0) 91%, #F9F9F9 98%);
            z-index: 9;
            pointer-events: none;  
        }
        .bread-crumbs:after {
            content: "";
            position: absolute;
            width: 78.9%; 
            height: 5.8%;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 91%, #F9F9F9 98%);
            z-index: 9;
            pointer-events: none; 
        }
        .bread-crumbs {
          overflow-x: scroll;
        }
        
        .bread-crumbs::-webkit-scrollbar {
          background: transparent;  
          width: 0px;
        }
        .bread-crumbs {
          scrollbar-width: none; 
          -ms-overflow-style: none;  
        }
        .bread-crumbs button {
            padding: $spacer $spacer * 2;
        }
        .pending-payments {
          .mymeds { 
              .label {
                  display: grid;
                  grid-template-columns: 23% 22% 22% 22% 11%;
                }
          }
      }
}

@media (min-width: 1024px) and (max-width: 1285px) and (orientation : landscape) {
  .box--bg {
    font-size: 14px; 
  }
  .box--6 {
    padding: 17px;
    grid-row-gap: 1.3rem
  }
  .login-grid {
    display: grid;
    grid-template-columns: map-get($grid-col-Bpts, '8-8');

      .login-user {
            grid-area: login-user;
            grid-column: 3 / 9;
            grid-template-columns: 1fr 1fr; 
            .login-access .login-form .box-sec {
                width: 350px;
            }
        }
  }
  .twofactor-grid {
    display: grid;
    grid-template-columns: map-get($grid-col-Bpts, '8-8');
  
      .twofactor-main {
          grid-area: twofactor-main; 
          grid-column: 3 / 9;
          grid-template-columns: 1fr 1fr;

          .twofactor-right {
              .sub-title{
                font-size: 14px;
              }
              width: auto;
              margin: 40px 20px 40px 0;
              .verification-form {
                margin-top: 40px;
                  .optFrm {
                    grid-template-columns: repeat(6, auto); 
                }
              }
          }
      }
    }
}
/* Error popup Styles Start */
  .error-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .error-popup-content {
    background-color: $white;
    padding: $spacer + 2 $spacer * 2 + 4;
    border-radius: $spacer;
    box-shadow: 0 2px 10px #0000001a;
    width: auto;
    text-align: center;
    position: relative;
    min-width: 300px;
  }

  .error-popup-close {
    position: absolute;
    top: 0;
    right: 6px;
    font-size: $spacer * 2 + 2;
    cursor: pointer;
  }
/* Error popup Styles End */

/*Signature Capture Styles Start*/
.signature-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.signatureCapture {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 8px;
    width: 460px;
    height: auto;
    padding: 24px;
    font-size: 14px;
    .close-button {
      background: transparent;
      font-size: 1em;
      cursor: pointer;
      color: #fff;
      border-radius: 50%;
      border: #535050;
      background-color: #535050;
      position: absolute;
      top: -9px;
      right: -9px;
    }
    .sign-header {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .heading {
      font-weight: 600;
    }
    .clear {
      font-weight: 500;
      color: #8F38E5;
      cursor: pointer;
    }
    .sign-footer {
      display: flex;
      justify-content: space-between;
      font-size: 16px
    }
    .sign-action-buttons {
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid #8F38E5;
      background: white;
      color: #8F38E5;
      padding: 8px;
      width: 100%;
    }
    canvas {
      border-radius: 8px;
      border: 1px solid #DBE2E4;
      background: #F9F9F9;
    }
    .enable-submit {
      background-color: #8F38E5;
      color: white;
      margin-left: 16px;
    }
    .disable-submit {
      background-color: #e2c4ff;
      color: #ac65ff;
      margin-left: 16px;
      border: none;
      cursor: no-drop;
    }
    .sign-agree-check {
      display: flex;
      align-items: flex-start
    }
    input {
      margin-top: 18px;
      margin-right: 7px;
    }
}
/*Signature Capture Styles End*/

/* Tablet view */
@media screen and (max-width: 1023px) {
 
  /* subscription start*/
  .sub-alert{ 
    grid-template-columns: map-get($grid-col-Bpts, '8-8');
    
    .sub-user{
        grid-area: sub-user;
        grid-column: 4 / 8;

        .sub-pharma {
            margin-bottom: 54px; 
        }
        .sub-access{ 
          height: 294px;
            .sub-img {
                margin-bottom: 23px;
            }
            .sub-msg{
              width: auto;
            }
        }
    }
  }
  /* subscription end*/
  /* Auth0 styles start */
    .auth0-login-grid { 
          grid-template-columns: map-get($grid-col-Bpts, '8-8');
          grid-template-areas: "login-user"; 
              .login-user {
                grid-area: login-user;
                grid-column: 3 / 9;
                height: 855px;
                grid-template-columns: 1fr;
                grid-template-rows: max-content max-content;

                  .login-pharma {
                      display: flex;
                      flex-direction: column;
                      padding: 73px 67px 0 67px;
                      .login-pharma-a {
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          margin-top: 48px;
                      }
                      .auth-img{
                          > img {
                              width: 163px;
                          }
                      }
                      .laptop{
                        img{
                          width: 274px;
                        }
                      }
                  }
                  .login-access {
                      .login-form{ 
                          max-width: 396px;
                          width:100%;
                        #btn-login{ 
                          font-size: 16px; 
                        }
                        .doyou{
                          font-family: Sora,sans-serif;
                          font-size: 14px;
                          padding-top:16px; 
                            button#btn-signup {  
                                cursor:pointer;
                            }
                        }
                      }
                      display: flex;
                      flex-direction: column; 
                      padding: 51px 36px 0 67px;
                      .title {
                          font-size: 24px; 
                          font-weight: 500;
                      }
                      .error-msg{
                        font-size: 16px;
                        margin: 8px 0 36px 0; 
                      }
                      .powerd-by{
                        margin-top: 47px;
                      }
                  }
            } 
        .login-pharma-img > img {
          width: 163px;
        }
    }
/* Auth0 styles End */
    /* Login styles start */
    .login-grid {
      display: grid;
      grid-template-columns: map-get($grid-col-Bpts, '8-8');

        .login-user {
            height: 855px;
            grid-area: login-user;
            grid-column: 3 / 9;
            grid-template-columns: 1fr;
            grid-template-rows: max-content max-content;
            .login-pharma {
                span:nth-child(1) ,span:nth-child(2) { 
                    font-size: 22px;
                }
                span:nth-child(3) { 
                  font-weight: 600;
                } 
                padding: 73px 67px 87px 67px;
                .laptop {
                    margin-top: 87px; 
                    display: flex;
                    align-items: center;
                }
            }
            .login-access {
                width: auto;
                .login-form { 
                  margin: 32px 0 32px 0px;
                  .box-sec{
                    width: 396px;
                  }
                }
                .login-form {
                    .guest-section {
                        margin-bottom: 56px;
                    }
                }
                
            }
          }
  }
  /* Login styles end */

  /* Two-factor styles start */
.twofactor-grid {
  grid-template-columns: map-get($grid-col-Bpts, '8-8');

    .twofactor-main {
        height: 855px;
        grid-column: 3 / 9;
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;

        .twofactor-left {
            padding: 73px 67px 0;
            .laptop {
                margin: 48px 62px 51px 60px;
            }
      }
      .twofactor-right {
          width: 396px;
          margin: 0 65px 0 67px;
          
          .verification-form {
              margin-top: 41px;
              .ver-code{
                font-size: 14px;
              }
              .optFrm {
                  grid-template-columns: repeat(6, 53px);
                  column-gap: $spacer * 2;
              }
              .verfity-btn {
                margin-top: $spacer * 3 + 3;
              }
              .text-blue {
                  margin-top: $spacer * 2;
              }
          }
          .powerd-by {
            margin-top: 52px;
          }
      }
    }

  }
/* Two-factor styles end */

  .wrapper {
    grid-template-columns: map-get($grid-col-Bpts, '8-8');
    column-gap: $spacer * 2;
    grid-column: 2 / span 8;
  }
  .head-nav {
    grid-template-columns: map-get($grid-col-Bpts, '8-8'); 
    column-gap: $spacer * 2;
    grid-column: 1 / span 8;
    box-shadow: 0px 2px 8px 0px $hb-shdw;
    border-bottom: 1px $hb-shdw solid;
    background: $white;
  }
  app-banner {
    grid-column: 2 / span 8;
    padding-top: 0px;
  }
  .hd--left {
      grid-column: 2 / 6;
  }
  .hd--right {
      grid-column: 8 / 10;
  }
  .heading--text {
    grid-column: 2 / span 8;
  }
  .heading--strip { 
    grid-column: 2 / span 8;
  }
  main {
    grid-template-columns: repeat(8, 1fr);
    grid-column: 2 / span 8;
  }
  .box--6 {
    grid-area: 3 / 5 / 4 / 9;
    flex-direction: row;
  }
  .box--5 {
    grid-area: 2 / 5 / 3 / 9;
  }
  .box--4 {
    grid-area: 3 / 1 / 4 / 5;
  }
  .box--bg {  
    font-size: 0;
  }
  .box--bg::after {
    content: "We're here to support you on your health journey.";
    font-size: 14px; 
  }
  .box .bannerGirl-bg {
      width: 74px;
  }
  .comp-address .title {
      font-size: 18px;
      font-weight: map-get($font-weights, 'fw-5');
  }
  .comp-address .address {
      font-size: 14px;
      font-weight: map-get($font-weights, 'fw-4');
  }
  span.msg {
      font-size: 22px;
      font-weight: map-get($font-weights, 'fw-5'); 
  }
  /* Profile start */
  .profile {
    grid-template-columns: map-get($grid-col-Bpts, '8-8');
    column-gap: $spacer * 2;
    grid-column: 2 / span 8;

    .user-img {
      grid-column: 2 / span 8;
    }
    .userdata {
      grid-column: 2 / span 8;
      > * {
          padding: $spacer * 3;
      }
      .userInfo--1 {
          grid-column: span 10;
      }
      .userInfo--2 {
          grid-column: span 10;
          #insuranceScroll {
            padding: 0 10px 0px 0px;
          }
      }
      .userInfo--3 {
          grid-column: span 10;
      }
      .userInfo--4 {
          grid-column: span 10;
      }
    }
  }

  /* Insurance Popup Styles Start*/

  .userInfo .insupopup_body {
    grid-template-columns: repeat(4, 150px);
    grid-template-rows: repeat(2, 60px);
  }
  /*.userInfo .insupopup_body label:has(+input[type=text]), .userInfo label:has(+input[type=tel]), .userInfo label:has(+select) {
    left: 0px;
  }*/
  .userInfo .left_label_bin_pcn {
    left: -35px !important;
  }
  .userInfo .left_label_priority {
    left: -25px !important;
  }
  .userInfo .insupopup_footer {
    padding: 45px 15px 15px 0px;
  }
  .userInfo .insurance_dropdown {
    grid-template-columns: 2fr 1fr 1.5fr;
  }
  .userInfo .insupopup_body .dropdown-menu.show {
    width: 470px;
    max-height: 50%;
    box-shadow: none;
  }
  .insupopup_body .dropdown-menu.show .no-result {
    width: 455px !important;
    height: 65px !important;
    p{
      font-size: 13px !important;
    }
  }
  .userInfo .label_text small {
    font-size: 10px;
    margin-left: 9px;
  }
  /* Insurance Popup Styles End /*
  /* Profile end */
  /* Pending Payment Start */
  .revieworder{
    grid-template-columns: map-get($grid-col-Bpts, '8-8');
    column-gap: $spacer * 2;
    grid-column: 2 / span 8;
    
    app-banner { 
        grid-column: 2 / span 8;
        padding: $spacer * 4 0 $spacer * 3 0;
    }
    .review-order {
      grid-column: 2 / span 8;
    }
    .order-place{
      grid-column: 2 / span 8;
    }
  }
  /* Pending Payment End */

  /* Payment Confirm start */
  .paymentConfirm {
    grid-template-columns: map-get($grid-col-Bpts, '8-8');
    column-gap: $spacer * 2;
    grid-column: 2 / span 8;
    
    app-banner { 
        grid-column: 2 / span 8;
        padding: $spacer * 4 0 $spacer * 3 0;
    }
    .payment-order {
      grid-column: 2 / span 8;
      .payment-list {
          padding-top: 42px;
          .payment-out{
            .payments {
                  .pay-btns {
                      margin-bottom: 136px;
                  }
              }
          }
      }
    }
  }
  /* Payment Confirm End */  

  /* History(Active Meds) Tablet Start */

  .history {
    grid-template-columns: map-get($grid-col-Bpts, '8-8');
    column-gap: $spacer * 2;
    grid-column: 2 / span 8;

    app-banner { 
        grid-column: 2 / span 8;
        padding: $spacer * 4 0 $spacer * 4;
    }
    .bread-crumbs { 
      grid-column: 2 / span 8;
      overflow-x: scroll;
      
      button { 
          padding: $spacer $spacer * 2 - 6;
      }
    }
    .bread-crumbs::-webkit-scrollbar {
      background: transparent;  
      width: 0px;
    }
    .bread-crumbs {
      scrollbar-width: none; 
      -ms-overflow-style: none;  
    }
    .bread-crumbs:before {
        content:"";
        position: absolute; 
        right: 0;
        width: 100%;  
        height: 4.6%;
        background: linear-gradient(to left, rgb(255 255 255 / 0) 91%, #F9F9F9 98%);
        z-index: 1;
        pointer-events: none;  
    }
    .bread-crumbs:after {
        content: "";
        position: absolute; 
        left: 0;
        width: 100%; 
        height: 4.6%;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 91%, #F9F9F9 98%);
        z-index: 1;
        pointer-events: none; 
    }
    .history-data {
      grid-column: 2 / span 8; 
      .title>span+span {
          display: inline-flex;
          align-items: center; 
      }
      .title>span+span:after { 
          font-size: 20px;
      }

      .mymeds{
        padding: 16px 12px 16px 16px;
        .label { 
          grid-template-columns: 19% 21% 35% 1fr;
        }
      }
      .pending-payments { 
          .mymeds .label {
            grid-template-columns: 23% 29% 16% 15% 11%;
            
            .meds-5 {
              align-items: flex-start;
            }
          }
      }
      .ready-dispense{

        .label {
            grid-template-columns: 1fr 1fr 1fr;
        }
      }
      .refills-due{
          .label { 
            grid-template-columns: 23% 25% 1fr;
          }
      }
      /* histroy filter dropdown styles start */
      .filter-container {
            .filter-group select {
                option {
                    font-size: 12px; 
                    padding: 0;
                    margin: 0;
                    line-height: 0.5px;
                }
            }
      }
    /* histroy filter dropdown styles end */
    } 
  }

  /* History Tablet End */
}

/* Mobile view */
@media screen and (max-width: 720px) {
  body, html {
    background-image: url(/assets/images/bgpattern-mobile.png) !important;
  }
  .spc-type{
      display: none;
  }
    /* subscription start*/
      .sub-alert{ 
        grid-template-columns: map-get($grid-col-Bpts, '0-4');
        
        .sub-user{
            grid-area: sub-user;
            grid-column: 2 / span 4;

            .sub-pharma {
                margin-bottom: 54px; 
                span {
                    font-size: 18px; 
                }
            }
            .sub-access{ 
              height: 366px;
              width: auto;
              padding: 67px 36px;
                .sub-title{
                    text-align: center;
                    width: 74%;
                    font-size: 18px;
                }
                .sub-img {
                    margin-bottom: 24px;
                }
                .sub-msg{
                    width: 84%;
                }
            }
        }
      }
      /* subscription end*/
   /* Auth0 styles start */
    .auth0-login-grid { 
        grid-template-columns: map-get($grid-col-Bpts, '16-4');
        grid-template-areas: "login-user"; 
            .login-user {
              height: 100%;
              grid-column: 2 / span 4;
              grid-template-columns: 1fr;
              grid-template-rows: max-content max-content;
              background-color: transparent;
              box-shadow: none;

                .login-pharma {
                    padding: 24px 8px;
                    .login-pharma-img {
                        span{
                          font-size: 14px;
                        }
                        span:nth-child(3) {
                          margin-top: 4px;
                        }
                    }
                    .login-pharma-a {
                        margin-top: 24px;
                    }
                    .auth-img {
                        > img {
                            width: 163px;
                        }
                    }
                    .laptop {
                      img {
                        width: 152px;
                      }
                    }
                }
                .login-access {
                    
                    .login-form{ 
                        label:has(+ input[type="email"]),
                        label:has(+ input[type="password"]) { 
                            background-color: #F9F9F9;
                        }
                        max-width: 396px;
                        width:100%;
                      #btn-login{ 
                        font-size: 16px; 
                      }
                      .doyou{ 
                            font-size: 12px;
                            padding-top:16px; 
                          button#btn-signup {  
                              cursor:pointer;
                          }
                      }
                    } 
                    padding: 0px 8px 0 8px;
                    .title {
                        font-size: 16px; 
                        font-weight: 500;
                    }
                    .error-msg{
                        font-size: 12px;
                        line-height: 1.2;
                        margin: 8px 0 16px 0; 
                    }
                    .powerd-by{
                        margin-top: 16px;
                        display: flex;
                        align-items: center;
                        > img {
                          height: 18px;
                        }
                    }
                }
          }
    }
    /* Auth0 styles End */

  /* Login styles start */
  .login-grid {
    display: grid;
    grid-template-columns: map-get($grid-col-Bpts, '16-4'); 

      .login-user {
          height: 100%;
          grid-area: login-user;
          grid-column: 2 / span 4;
          grid-template-columns: 1fr;
          grid-template-rows: max-content max-content;
          background-color: transparent;
          box-shadow: none;
          .login-pharma {
              padding: $spacer * 3 0 $spacer * 2 0;
              span:nth-child(1), span:nth-child(2) { 
                  font-size: 14px;
              }
              span:nth-child(3) { 
                  font-weight: 600;
              }
              .laptop {
                  margin-top: $spacer * 2; 
                  display: flex;
                  align-items: center;
              }
              .login-pharma-img {
                  > img {
                      width: 152px;
                  }
              }
          }
          .login-access {
              .title{
                font-size: $spacer * 2;
              }
              width: 100%;
              .login-form { 
                margin: $spacer * 2 0 $spacer * 2 0px;
                .box-sec{
                  padding: $spacer;
                  width: auto;
                  align-items: center;
                  .login-title {
                    margin-left: $spacer;
                    span:nth-child(1){
                      font-size: $spacer * 2;
                      line-height: 1.4;
                      font-weight: 500;
                    }
                    span:nth-child(2){
                      font-size: $spacer * 2 - 4;
                      line-height: 1.4;
                    }
                  }
                }
              }
              .login-form {
                  .guest-section {
                    margin-bottom: $spacer * 2
                  }
              }
              .powerd-by>img {
                  height: 18px;
              }            
          }
        }
}
/* Login styles end */

/* Two-factor styles start */
.twofactor-grid {
  grid-template-columns: map-get($grid-col-Bpts, '0-4');

    .twofactor-main {
        height: 100%;
        grid-column: 2 / span 4;
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;
        background-color: transparent;
        box-shadow: none;

        .twofactor-left {
            padding: $spacer * 3 0px 0 $spacer * 2;
            .laptop {
                margin: 30px 47px 30px 55px;
                display: flex;
                align-items: center;
            }
            .login-pharma-img {
              > img {
                  width: 152px;
              }
              span { 
                  font-size: 14px;
                  line-height: .9;
              }
            }
      }
      .twofactor-right {
          width: auto;
          margin: $spacer * 2;
          .title {
              font-size: $spacer * 2; 
          }
          .sub-title { 
              font-size: $spacer + 4; 
          }
          .verification-form {
              margin-top: 24px;
              .ver-code{
                font-size: 14px;
                margin-bottom: $spacer * 2;
              }
              .optFrm {
                  grid-template-columns: repeat(6, auto);
                  column-gap: $spacer * 2;
              }
              .verfity-btn {
                  margin-top: $spacer * 2;
              }
              .text-blue {
                  margin-top: $spacer * 2;
              }
          }
          .powerd-by {
              margin-top: $spacer * 2;
          }
      }
    }

  }
/* Two-factor styles end */

  .wrapper {
    grid-template-columns: map-get($grid-col-Bpts, '0-4');
    column-gap: $spacer * 2;
    grid-column: 2 / span 6;
  }
  .head-nav {
    grid-template-columns: map-get($grid-col-Bpts, '0-4'); 
    column-gap: $spacer * 2;
    grid-column: 1 / span 6;
    box-shadow: 0px 2px 8px 0px $hb-shdw;
    border-bottom: 1px $hb-shdw solid;
    background: $white;
  }
  app-banner {
    grid-column: 2 / span 4;
  }
  /* Profile start */
  .profile {
    grid-template-columns: map-get($grid-col-Bpts, '0-4');
    column-gap: $spacer * 2;
    grid-column: 2 / span 8;
    .userdata{
      padding-right: 7px;
    }
    .user-img {
      grid-column: 2 / span 4;
      .profileImg { 
          width: 80px;
          height: 80px;
      }
      .user-info {
        .user-name {
          font-size: $spacer * 2 - 2;
          font-weight: map-get($font-weights, 'fw-5');
        }
      }
    }
    .userdata {
      grid-column: 2 / span 4;
      > * {
          padding: $spacer * 2;
      }
      .userInfo--1 {
          grid-column: span 10;
      }
      .userInfo--2 {
          grid-column: span 10;
          #insuranceScroll {
            padding: 0 10px 0px 0px;
          }
          #insuranceNoScroll {
            padding: 0;
        }
      }
      .userInfo--3 {
          grid-column: span 10;
      }
      .userInfo--4 {
          grid-column: span 10;
      }
    }
  }
  .userInfo .insu_data {
    display: block;
    margin-bottom: 25px;
    margin-left: 40px;
  }
  .userInfo {
    .insupopup_body {
        height: 350px;
        overflow-y: scroll;
        display: grid;
        grid-template-rows: repeat(8, 10px);
        grid-template-columns: repeat(1, 300px);
        gap: 60px;
      }
      .mobile_view1{
          background-size: cover;
          background-position: 8px -151px;
          background-repeat: no-repeat;
          z-index: 7;
          position: fixed;
          top: 94%;
          background-image: url(/assets/images/mob2_insu.png);
          margin-left: -7px;
          height: 7%;
          width: 91%;
        }
  
        .mobile_view2{
          background-size: cover;
          background-position: 0 -138px;
          background-repeat: no-repeat;
          z-index: 6;
          position: fixed;
          width: 84%;
          background-image: url(/assets/images/mob3_insu.png);
          top: 98%;
          height: 9%; 
      }
  }
  .insupopup_body  label:has(+input[type=text]),label:has(+input[type=tel]),label:has(+select) { 
    left: 20px;
  }
  .userInfo .insupopup_body .dropdown-menu.show {
    width: 300px;
    height: 275px;
  }
  .insupopup_body .dropdown-menu.show .no-result {
    width: 100% !important;
    p{
      padding-left: 0 !important;
    }
  }
  .insupopup_body{
    .margin_top{
      margin-top: -11px;
    }
    .margin_bottom{
      margin-bottom: 10px;
    }
    .label_text{
        label{
          left: -89px;
        }
        .margin-top{
          margin-top: -10px;
        }
    }
    .dropdown-item { 
        border: 0px solid #ededed;
        /* border-bottom: 0px; */
        padding: 0;
    }
    .insurance_dropdown{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        width: 297px;
        background-color: #fff;
        margin-left: 0px;
        padding: 12px;
      .card{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .card-1,
      .card-2,
      .card-3 {
        span:nth-child(1) {
          font-size: 12px;
          color: #5B5C64;
          font-weight: 400;
        }
        span:nth-child(2) {
          font-size: 14px;
          color: #2E2F35;
          font-weight: 400;
        }
      }
      .card-1 {
        grid-area: 1 / 1 / 2 / 3;
      }
    }
    .insurance_dropdown .card:hover{
      background-color: #FAF5FF;
    }
  }
  .userInfo .insupopup_footer {
    display: grid;
    gap: 10px;
    justify-content: center;
    padding: 10px;
  }
  .userInfo .insupopup_body .dropdown-control {
    width: calc(100% - 0px);
  }
  .userInfo .insupopup_modal_container{
    top: 50%;
    height: 550px;
  }
  .userInfo .insupopup_footer .insu_buttons {
    width: 300px;
  }
  .insu_info{
    width: 268px !important;
    margin: 0 15px 0 15px !important;
    background-image: url("/assets/images/mob1_insu.png");
  }
  .userInfo .insu_info .insu_info_id {
    grid-template-columns: repeat(3, 80px);
}
 
  .card_zindex{
    z-index: 8;
  }
  .desktop-only {
    display: none;
  }
  .userInfo{
    .data_check_exists {
      border: 1px solid rgba(255, 60, 0, .2);
      background: rgba(255, 60, 0, .1019607843);
      width: 300px;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      margin-left: 10px;
      z-index: 1009;
      position: fixed;
      color: red;
      top: 22.7%;
      font-size: 12px;
      text-align: left;
        span{
          color: red;
          padding-right: 5px;
          padding-left: 5px;
          font-size: 20px;
        }
    }
  }
  .userInfo{
    .data_check_inactive{
      width: 300px !important;
  }
  }
  .userInfo .info_insurance{
    grid-template-columns: auto;
    z-index: 8;
  }
  /* Profile end */
  /* Pending Payment Start */
  .revieworder {
    grid-template-columns: map-get($grid-col-Bpts, '0-4');
    column-gap: $spacer * 2;
    grid-column: 2 / span 4;
    
    app-banner { 
        grid-column: 2 / span 4;
        padding: $spacer * 2 0 $spacer * 2;
    }
    .review-order {
      grid-column: 2 / span 4;
    }
    .order-place {
        grid-column: 1 / -1;
        background-color: $white;
        position: absolute;
        z-index: 10;
        bottom: 0;
        width: 100%;

        .section {
            margin-top:0px;
            grid-template-columns: unset;
            align-content: center;
            grid-auto-flow: row;
            justify-items: center;
            border-top: #DFE4E9 solid 1px;  
                .sec--1 {
                  
                  padding: $spacer 0;
                    .amt-label {
                        font-size: 12px;
                      >span {
                          font-size: $spacer * 2;
                      }
                    }
                }
                .sec--2 {
                  margin-left:0px;  
                  width: 100%;
                  padding: $spacer * 2;
                  border-top: #DFE4E9 solid 1px;
                  button {
                    width: 100%;
                    justify-content: center;
                  }
              }
        }
    }
    .order-list {
        display: grid;
        grid-template-columns: 25% 1fr 1fr 1fr;
        grid-template-rows: max-content max-content;
        column-gap: $spacer;
        row-gap: $spacer;
        padding: 8px 8px 10px 8px;

        .item {
            display: flex;
            flex-direction: column;

            > img.drug-img {
                width: 100%; 
            }
        }
        .item--1 {
            grid-area: 1 / 1 / 3 / 2;
        }
        .item--5 {
            grid-area: 2 / 2 / 3 / 5;
        }
    }
  }
  /* Pending Payment End */

  /* Payment Confirm start */
  .paymentConfirm {
    grid-template-columns: map-get($grid-col-Bpts, '0-4');
    column-gap: $spacer * 2;
    grid-column: 2 / span 4;
    
    app-banner { 
        grid-column: 2 / span 4;
        padding: $spacer * 2 0 $spacer * 2;
    }
    .payment-order {
      grid-column: 2 / span 4;
      .payment-list {
          .payment-succ {
              img {
                width: auto;
                height: 70px;
              }
          }
          /* print reciept scroll start */
          max-height: 398px;
          overflow-y: auto;
          /* print reciept scroll end */
          padding-top: $spacer;
          .payment-out {
              width: auto;
              .item {
                padding: 4px $spacer * 2;
              }
              .item > span{
                font-size: 12px;
              }
              .item span + span {
                font-size: 14px;
                font-family: $sora-font-family;
              }
              .item--1 {
                  margin-bottom: 12px;
                  > span { 
                      font-size: $spacer * 2;
                  }
              }
              .item--2 {
                    margin-bottom: $spacer * 2 + 2; 
                    span {
                        text-align: center;
                        font-size: 13px;
                        width: 70%;
                    }
                }
              .item--3 {
                height: 35px;
              }
              .item--8 {
                padding-bottom: $spacer * 2;
              }
              .payments {
                  width: 100%;
                  bottom: 0;
                  position: absolute;
                  z-index: 10;
                  background-color: $white;
                  padding: $spacer * 2;
                  .pay-btns {
                      margin: 0;
                  }
              }
          }
      }
    }
  }
  /* Payment Confirm End */ 

  /* History Mobile Start */
  .history {
    grid-template-columns: map-get($grid-col-Bpts, '0-4');
    column-gap: $spacer * 2;
    grid-column: 2 / span 4;

    app-banner { 
        grid-column: 2 / span 4;
        padding: $spacer * 2 0 $spacer * 2;
    }
    .bread-crumbs { 
      grid-column: 2 / span 4;
      display: grid;
      grid-template-columns:190px 129px 198px 190px 141px;
      column-gap: 8px;
      padding-bottom: 3px;
      button { 
        font-size: 14px;
        padding: 8px;
      }
      .meds, .ready, .new, .refills{
        margin-left: $spacer;
      }
    }
    .bread-crumbs:before, .bread-crumbs:after {
      width: 100%;
      height: 7%;
    }
    .history-data {
      grid-column: 2 / span 4;
      margin-bottom: $spacer * 2 + 4;
      .hist-switch{
          .sticky-header{
            height: 38px; 
          }
          .sec1 {
            > .title{
              margin-top: 10px;
            }
          }
      }
      .title { 
        padding-top: $spacer * 2;
        position: sticky;
        top: -1px;
        background-color: $body-color;
      }
      /* accordion start*/ 
      .item.active .label {
          height: 256px; 
      }
      .ready-dispense{
          margin-top: 0px;
          .item .label { 
            height: 77px;
          }
          .item.active .label {
            height: 220px;
          }
      }
      /* accordion end*/

      /* Pending Payments Mobile start */
      .pending-payments {
          margin-top: 0px;
            .item .label { 
              height: 38px;
            }
            .item.active .label {
              height: 117px;
            }
            .item.active .label::before { 
                content: '\e316'; 
            }
            .item .label:before {
              content: "\e313";
            } 
            .mymeds.active{
              border: $pending-pay-clr solid 1px;
            }
          
          .mymeds {
              .label {
                  display: grid;
                  grid-template-columns: 44% 1fr;
                  grid-template-rows: repeat(3, auto);
                  row-gap: $spacer;
                  column-gap: $spacer;
                  .meds-1 {
                    grid-area: 1 / 1;
                  }
                  .meds-2 {
                    grid-area: 1 / 2;
                  }
                  .meds-3 {
                    grid-area: 2 / 1;
                  }
                  .meds-4 {
                    grid-area: 2 / 2;
                  }
                  .meds-5 {
                    grid-area: 3 / 1 / 4 / 3;
                  }
                  button.btn-pending { 
                    width: 100%; 
                    justify-content: center;
                    font-size: 14px;
                    height: 26px;
                  }
              }
        }
      }
      /* Pending Payments Mobile end */
      .mymeds {
        
          .label{ 
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-template-rows: repeat(6, auto);
              row-gap: $spacer;

              .meds-1 {
                grid-area: 1 / 1;
              }
              .meds-2 {
                grid-area: 1 / 2;
              }
              .meds-3 {
                grid-area: 2 / 1 / 3 / 3;
              }
              .meds-4 {
                grid-area: 3 / 1;
              }
              .meds-5 {
                grid-area: 3 / 2;
              }
              .meds-6 {
                grid-area: 4 / 1 / 5 / 3;
              }
              .meds-7 {
                grid-area: 5 / 1 / 6 / 3;
              }
              .meds-8 {
                grid-area: 6 / 1;
              }
              .meds {
                >span+span { 
                    height: auto;
                }
              }
        }
      }
      /* Ready To Dispense Mobile Start */
      .ready-dispense{

        .label {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(5, auto);
            row-gap: $spacer;
            .meds-1 { 
              grid-area: 1 / 1 / 2 / 3;
            }
            .meds-2 { 
              grid-area: 4 / 1;
            }
            .meds-3 { 
              grid-area: 2 / 1 / 3 / 3;
            }
            .meds-4 { 
              grid-area: 4 / 2;
            }
            .meds-5 {
              grid-area: 3 / 1 / 4 / 3;
            }
            .meds-6 {
              grid-area: 5 / 1; 
            }
        }
        .mymeds {
          padding: 16px 8px 16px 16px;
        }
      }
      /* Ready To Dispense Mobile End */ 

      /* New Prescription Mobile Start */ 
      .new-prescription{
          margin-top: 0px;
          .item .label { 
            height: 77px;
          }
          .item.active .label {
            height: 130px;
          }
          .item.active .label::before { 
              content: '\e316'; 
          }
          .item .label:before {
            content: "\e313";
          } 
          .mymeds.active{
            border: $new-Pre-clr solid 1px;
          }
        .mymeds { 
            padding-left: $spacer * 2;
            .label {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(3, auto);
                row-gap: $spacer;
                .meds-1 { 
                  grid-area: 1 / 1;
                }
                .meds-2 { 
                  grid-area: 1 / 2;
                }
                .meds-3 { 
                  grid-area: 2 / 1 / 3 / 3;
                }
                .meds-4 { 
                  grid-area: 3 / 1;
                }
            }
        }
      }
      /* New Prescription Mobile End */

      /* refillDue start */
      .refillsDue-scroll{

          .refills-due {
                margin-bottom: 1px;
                .title { 
                    height: 37px;
                }
                .mymeds {
                  padding-left: $spacer * 2;
                }

                .item .label:before { 
                  right: -4px;
                }
                .item .label { 
                  height: 79px;
                }
                .item.active .label {
                  height: 170px;
                }

                .label {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: repeat(4, auto);
                    row-gap: $spacer;
                    .meds-1 { 
                      grid-area: 1 / 1;
                    }
                    .meds-2 { 
                      grid-area: 3/ 1 / 4 / 3;
                    }
                    .meds-3 { 
                      grid-area: 1 / 2;
                    }
                    .meds-4 { 
                      grid-area: 4 / 1;
                    }
                    .meds-5 {
                      grid-area: 2 / 1 / 3 / 3;
                    }
                    .meds-6 {
                      grid-area: 4 / 2; 
                    }

                    .refillItems input {
                        top: 0px;
                        position: absolute;
                        right: 21px;
                    }
              }
          }
            .authSection { 
              padding: $spacer * 2
            }
            .authSection {
                .authBtn{
                  width: 100%;
                  justify-content: center;
                  height: 34px;
                }
            }  
      }
      /* refillDue end */

    }
    /* Hide scroll bar history screen */
    .history-data::-webkit-scrollbar {
      background: transparent;  
      width: 0px;
    }
    .history-data {
      scrollbar-width: none; 
      -ms-overflow-style: none;  
    }
  } 
  /* History Mobile End */  
  .heading--text {
      grid-column: 2 / span 4;
      align-items: center;
      margin: 8px 0 5px;
  }
  .heading--strip {
      display: grid;
      grid-column: 2 / span 4;
      background-image: url(/assets/images/bg_mobile_2x.png);
      background-size: cover;
      padding: 7px 16px 7px 16px;
      height: 96px;
  }
  .comp-address .title {
      font-size: 15px;
      font-weight: map-get($font-weights, 'fw-5');
      display: flex;
      align-items: flex-start;
  }
  .comp-address .address {
      font-size: 13px;
      font-weight: map-get($font-weights, 'fw-4');
      grid-area:2;
      margin: -5px 0 0 0;
      line-height: 13px;
      display: flex;
      align-items: flex-start;
  }
  .PhNum {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .PhNum span {
      padding-right: 5px;
      font-size: 14px;
      font-weight: map-get($font-weights, 'fw-6');
  }
  .hd--left {
    grid-column: 2 / 5;
    display: grid;
  }
  .logo {
    grid-column: 3 / 3;
  }
  .hd--right {
    grid-column: 5 / 6;
    a {
        width: 37px;
    }
  }
  span.msg {
    font-size: $spacer * 2;
  }
  .hb-icon {
    height: 39px;
  }
  .comp-address {
    grid-column: 1 / 2;
    display: grid;
    grid-auto-rows: minmax(26px,auto);
  }
  .box .bannerGirl-bg {
      width: 54px;
  }
  .comp-phone {
    grid-column: 1 / 2;
    position: absolute;
    padding-top: 50px; 
  }
  
  .phrma-logo {
    grid-area: 1 / 2; 
  }

  main {
    grid-template-columns: map-get($grid-col-Bpts, '0-4');
    grid-column: 1 / span 6;
    grid-template-rows: repeat(6, auto);
    grid-auto-flow: column;
    row-gap: $spacer;
    margin-top: $spacer * 2;
  }
  .box {
      height: 56px; 
      padding: $spacer $spacer * 2;
    
      .left-box > img {
        width: $spacer * 3;
      }
      .middle-box { 
        font-size: $spacer * 2; 
        padding-left: $spacer;
      }
      .right-box {
        font-size: $spacer * 2;
      }
  }
  .box--1 {
    grid-area: 1 / 2 / 2 / 6; 
  }
  .box--2 {
    grid-area: 2 / 2 / 3 / 6;  
  }
  .box--3 {
    grid-area: 3 / 2 / 4 / 6; 
  }
  .box--4 {
    grid-area: 5 / 2 / 6 / 6; 
  }
  .box--5 {
    grid-area: 4 / 2 / 5 / 6; 
  }
  .box--6 {
    grid-area: 6 / 2 / 7 / 6; 
  }
  .error-popup-content { 
      width: 250px;
  }
  /* Profile styles range between 320px and 430px */
    @media (max-width: 330px) {
      .history .history-data .pending-payments .item.active .label {
          height: 135px;
      }
    }
    @media (min-width: 350px) and (max-width: 500px) {
      .signatureCapture {
        width: 350px;
        .sign-footer {
          display: inline;
        }
        .submit {
          margin-left: 0px;
          margin-top: 8px
        }
        .close-button {
          display: none;
        }
      }
    }
    @media (max-width: 350px) {
      .signatureCapture {
        width: 300px;
        .sign-footer {
          display: inline;
        }
        .submit {
          margin-left: 0px;
          margin-top: 8px
        }
        .close-button {
          display: none;
        }
      }
    }
    @media (min-width: 320px) and (max-width: 430px) {
        .history .history-data .refillsDue-scroll .refills-due .label .refillItems input { 
            right: 13px;
        }
        .history .history-data .ready-dispense .item.active .label {
            height: 227px;
        }
        #insuranceScroll .title {
            padding-top: 0px;
            flex-direction: row;
        }
        .userInfo {
            display: flex;
            flex-direction: column;
            .info-item {
              flex-direction: column;
              align-items: flex-start;
            }
            .info-value { 
              text-align: left;
              margin-top: 2px;
            } 
            .title { 
              flex-direction: row;
              padding-bottom: 8px;
              margin: 0px;
              display: flex;
              align-items: center;
            }
        }
        .history .history-data .refillsDue-scroll .refills-due .label {
          grid-template-columns: 1fr 55%;
        }
    }
}