//default input style changes
input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  background: #fff;
  font-family: var(--font-family);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--black-color);
  padding-left: 8px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid var(--blue-color);
  background: transparent;
}

label:has(+ input[type="text"]),
label:has(+ input[type="email"]),
label:has(+ input[type="password"]) {
  position: relative;
  top: -10px;
  left: 16px;
  background-color: #fff;
  padding: 2px 4px;
  font-size: 8px;
  color: var(--black-color);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.2px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}
